import React, { useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import {
  AiFillDelete,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import SuccessPasswordModal from "./SuccessPasswordModal";
import { httpRequest } from "../../Apis/commonApis";
import { useForm } from 'react-hook-form';
const ResetPasswordModal = (props: any) => {
  let { show, handleClose,userData,params,api } = props;
  const { register, handleSubmit,reset, setValue  ,formState: { errors }, } = useForm();
  const [error,setError] = useState("");
  const [iconShow,setShowIcon] = useState(true)
  const [conShowIcon,setconShowIcon] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false);
  const handleSuccessClose = () => setShowSuccess(false);
  const handleSuccessShow = () => setShowSuccess(true);


  const resetPasswordUser = async (item:any) => {
    // e.preventdefault()
    if(item.password !== item.confirmPassword){
      setError("password does not match")
      return;
    }
    let data = {
      newPassword : item.password,
      confirmPassword:item.confirmPassword,
    }
    const res = await httpRequest(`${api}/${userData}`,"PATCH", data, "json");
    if(res?.status == true){
      handleSuccessShow();
      handleClose();
      reset();
      setValue("password" , "")
      setValue("confirmPassword", "")
      setError("");
    }else{
      setError(res?.message)
    }
   
  };
  
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts} ${m.resetPopUp}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Reset Password</h5>
          </Col>
          <div className={`${m.reset_Body}`}>
            <Row>
              <Col md={12} lg={12}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className={`${st.formField} ${m.formField_input} mb-3`}>
                      <Form.Label>
                        New Password<span className="text-danger">*</span>
                      </Form.Label>
                      <div className={`${m.add_icon}`}>
                        <input  type={iconShow ? "password" : "text"} className="form-control"
                        {...register("password", {
                          required: "This password is required",
                          minLength: {
                            value: 6,
                            message: "Please enter minimum 6 characters",
                          },
                        })}
                         />
                         {iconShow ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShowIcon(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShowIcon(true);
                            }}
                          />
                        )}
                        {/* <IoMdEyeOff  /> */}
                      </div>
                      {errors?.password?.type === "minLength" && (
                          <p className={"errorMessage"}>
                            Please enter minimum 6 characters
                          </p>
                        )}
                        {errors?.password?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className={`${st.formField} ${m.formField_input} mb-3`}>
                      <Form.Label>
                        Confirm Password<span className="text-danger">*</span>
                      </Form.Label>
                      <div className={`${m.add_icon}`}>
                        <input  className="form-control"
                        type={conShowIcon ? "password" : "text"}
                        //  onChange={(e)=>{setPassword(e.target.value)}}
                         {...register("confirmPassword", {
                          required: "This password is required",
                          
                          minLength: {
                            value: 6,
                            message: "Please enter minimum 6 characters",
                          },
                        })}
                         />
                        {/* <IoEye /> */}
                        {conShowIcon ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setconShowIcon(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setconShowIcon(true);
                            }}
                          />
                        )}
                      </div>
                      {errors?.confirmPassword?.type === "minLength" && (
                          <p className={"errorMessage"}>
                            Please enter minimum 6 characters
                          </p>
                        )}
                        {errors?.confirmPassword?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                    </Form.Group>
                    <p>{error}</p>
                  </Col>


                  <Col lg={12} className="mt-2">
                    <Button className={`${st.btnDisabled}`} onClick={handleSubmit(resetPasswordUser)}>Save</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessPasswordModal  show={showSuccess} handleClose={handleSuccessClose} clientId ={params?.id}/>
    </>

    
  );
};

export default ResetPasswordModal;
