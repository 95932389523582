import React from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";

const CMSM = (props: any) => {
  let { show, handleClose } = props;
  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Add Business User</h5>
          </Col>
          <Row>
            <Col md={12} lg={12}>
              <Row>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Business Name<span className="text-danger">*</span>
                    </Form.Label>
                    <input type="text" className="form-control" />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      First Name<span className="text-danger">*</span>
                    </Form.Label>
                    <input type="text" className="form-control" />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Last Name<span className="text-danger">*</span>
                    </Form.Label>
                    <input type="text" className="form-control" />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <input type="email" className="form-control" />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Phone Number<span className="text-danger">*</span>
                    </Form.Label>
                    <input type="number" className="form-control" />
                  </Form.Group>
                </Col>

                <Col lg={12} className="mt-2">
                  <Button className={`${st.btnDisabled}`}>Save</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CMSM;
