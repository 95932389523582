import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "./index.module.scss";
import st from "./style.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import loginBg from "../../images/loginBg.png";
import { useForm } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { httpRequest } from "../../Apis/commonApis";
const Login = (props: any) => {
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState<string>("first");
  const [show, setShow] = useState(true);
  const [error, seterror] = useState("");
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  setTimeout(() => {
    seterror("")
  }, 6000);
  const handleStep = (e: any, step: string) => { e.preventDefault(); setLoginState(step); };

  const superAdminLogin = async (postData: any) => {
    setLoading(true)
    let data = {
      email: postData.email,
      password: postData.password,
    }
    const res = await httpRequest("superAdmin_login", "post", data, "withoutToken");
    if (res.status === true) {
      localStorage.setItem('superAdmin_data', JSON.stringify(res?.result));
      localStorage.setItem('superAdmin_token', res?.token);
      setTimeout(() => {
        navigate("/business/business-user", { replace: false });
      }, 1000);
    } else {
      seterror(res.message)
    }
    setLoading(false);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(superAdminLogin)(event);
    }
  };

  return (
    <>
      <section className={`${cx.loginSection}`}>
        <img src={loginBg} alt="login background" className={`${cx.loginBg}`} />
        <div className={`${cx.modalContent}`}>
          <div className={`${cx.modalCenter}`}>
            {loginState === "first" ? (
              <div className={`${cx.formBody}`} onKeyDown={handleKeyDown}>
                <Row>
                  <Col md={12}>
                    <h2 className={`${cx.title}`}>Let’s Go!</h2>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox} ${st.iconFormBox} `}>
                      <label className="form-label">
                        Email
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          style={{ padding: "0.375rem 0.95rem" }}
                          placeholder="Email or phone number"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                        {/* /> */}
                      </div>
                      {errors?.email?.type === "pattern" && (
                        <p className={"errorMessage"}>Invalid email address</p>
                      )}
                      {errors?.email?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}

                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      className={`${st.formBox} ${st.iconFormBox} ${cx.password}`}
                    >
                      <label className="form-label">Password</label>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          placeholder="Your password"
                          type={show ? "password" : "text"}
                          {...register("password", {
                            required: "This password is required",
                            minLength: {
                              value: 6,
                              message: "Please enter minimum 6 characters",
                            },
                          })}
                        />
                        {show ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(true);
                            }}
                          />
                        )}
                      </div>
                      {errors?.password?.type === "minLength" && (
                        <p className={"errorMessage"}>
                          Please enter minimum 6 characters
                        </p>
                      )}
                      {errors?.password?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <NavLink
                      className={`${cx.forgotBox}`}
                      to="#"
                      onClick={(e: any) => handleStep(e, "forgotPassword")}
                    >
                      Forgot Password?
                    </NavLink>
                  </Col>
                  <Col md={12}>
                    <div className={"errorMessage"}>{error}</div>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup} mt-2`}>
                        <Button className={`btn ${st.btn3} ${cx.button}`} onClick={handleSubmit(superAdminLogin)}>
                          {loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            ></div>
                          ) : 'Login'}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : loginState === "forgotPassword" ? (
              <div className={`${cx.formBody}`}>
                <Row>
                  <Col md={12}>
                    <h2 className={`${cx.title}`}>Update your password</h2>
                  </Col>
                  <Col md={12}>
                    <div
                      className={`${st.formBox} ${st.iconFormBox} ${cx.password}`}
                    >
                      <label className="form-label">
                        Enter your email address{" "}
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup} mt-2`}>
                        <button
                          className={`btn ${st.btn3} ${cx.button}`}
                          onClick={(e: any) => handleStep(e, "newPassword")}
                        >
                          Send me the link
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center">
                      {" "}
                      Back to&nbsp;
                      <NavLink
                        className={`${cx.alreadyBox} m-0`}
                        to="#"
                        onClick={(e) => handleStep(e, "first")}
                      >
                        Log In
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : loginState === "newPassword" ? (
              <div className={`${cx.formBody}`}>
                <Row>
                  <Col md={12}>
                    <h2 className={`${cx.title}`}>Enter new password</h2>
                  </Col>
                  <Col md={12} className="mb-3">
                    <div
                      className={`${st.formBox} ${st.iconFormBox} ${cx.password}`}
                    >
                      <label className="form-label">New Password</label>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          placeholder="Your password"
                          type={show ? "password" : "text"}
                        />
                        {show ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      className={`${st.formBox} ${st.iconFormBox} ${cx.password}`}
                    >
                      <label className="form-label">
                        Re-enter Your New Password
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          placeholder="Your password"
                          type={show ? "password" : "text"}
                        />
                        {show ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup} mt-2`}>
                        <button
                          className={`btn ${st.btn3} ${cx.button}`}
                          onClick={(e: any) => handleStep(e, "last")}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center">
                      {" "}
                      Back to&nbsp;
                      <NavLink
                        className={`${cx.alreadyBox} m-0`}
                        to="#"
                        onClick={(e) => handleStep(e, "second")}
                      >
                        Log In
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={`${cx.formBody}`}>
                <Row className="mx-auto">
                  <Col md={12}>
                    <div className={`${cx.successfulBox}`}>
                      <h2>Successful password reset!</h2>
                      <p>
                        You can now use your new password to log in to your
                        account
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
