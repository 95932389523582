import React, { useCallback, useState, useEffect } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./BranchAndLocation.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import FadeLoader from "react-spinners/FadeLoader";
// --------- Images --------- //
import TableDataGrid from "./DataGrid";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import BusinessInfoM from "../../../components/Modals/BusinessInfoModal";
import { httpRequest } from "../../../Apis/commonApis";

export default function BusinessInfo() {
  const [show, setShow] = useState(false);
  const [setbusinessINfo, setsetbusinessInfo] = useState<any>([]);
  const [BusinessCategory, setBusinessCategory] = useState<any>([]);
  let [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getBusinessInfo();
    getbusinessCatagory();
  }, []);
  const getBusinessInfo = async () => {
    const res = await httpRequest("get_BusinessInfo?type=list", "get", null, "withoutToken");
    if (res?.status == true) {
      setLoading(false)
      setsetbusinessInfo(res?.data)
    }
  };
  const getbusinessCatagory = async () => {
    const res: any = await httpRequest("get_Catagory", "Get", null, "withoutToken");
    if (res?.status == true) {
      setBusinessCategory(res?.data)
    } else {
      setLoading(false)
    }
  };
  useEffect(() => {
    getbusinessCatagory();
  }, []);
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <BusinessMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Business Information</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>
        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink
                      to="#"
                      className={`btn ${tb.addBtn}`}
                      onClick={handleShow}
                    >
                      <AiOutlinePlusCircle className="me-1" />
                      Add New Business Info
                    </NavLink>
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" />
                    </button>
                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                {loading === true ? (
                  <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />
                ) : (<TableDataGrid data={setbusinessINfo} getBusinessInfo={getBusinessInfo} />)}
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <BusinessInfoM show={show} BusinessCategory={BusinessCategory} handleClose={handleClose} />
    </>
  );
}
