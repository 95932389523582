import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import ServiceM from "../../../components/Modals/ServiceModal";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getBusinessService } = props;
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteService = async () => {
    const res = await httpRequest(`delete_business_price/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status === true) {
      getBusinessService();
      handleClose();
    } else {
      setError(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
            <Status status={params?.status} api="update_service_status" id={params?.id}
              apiMethod="json" getLatest={getBusinessService} />
          </li> */}
        </ul>
      </div>

      <ServiceM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteService={deleteService} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let { serviceData, getBusinessService } = props;
  let row: any = serviceData?.map((val: any, index: any) => {
    return {
      serialNumber: index + 1,
      id: val?._id,
      name: val?.BusinessService?.serviceName,
      category: val?.BusinessService?.ServiceCategory?.serviceCategoryName,
      amount: val?.seasonPrice,
      status: val?.status,
      BusinessName: val?.Business?.businessName,
      BranchName: val?.branchId?.map((item: any) => item?.branchName).join(","),
      time: val?.duration,
      action: "",
    }
  })
  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60, renderCell: renderCellWithTooltip },
    // {
    //   field: "action", headerName: "Action", flex: 1, minWidth: 280,
    //   renderCell: (params: any) => <StatusButton params={params?.row} getBusinessService={getBusinessService} />,
    // },
    { field: "name", headerName: "Service Name", flex: 2, minWidth: 200, renderCell: renderCellWithTooltip },
    { field: "category", headerName: "Category", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "amount", headerName: "Amount", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "BusinessName", headerName: "Business Name", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "BranchName", headerName: "Branch Name", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "time", headerName: "Time", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },

  ];


  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        pageSize={100}
        autoHeight
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
