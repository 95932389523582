import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);
const columns = [
  { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "services",
    headerName: "Services",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "packagesId",
    headerName: "Packages",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "membership",
    headerName: "Membership",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "businessName",
    headerName: "BusinessName",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "createdby",
    headerName: "Createdby",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "createdDate",
    headerName: "CreatedDate",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
  {
    field: "price",
    headerName: "Price",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip
  },
];

export default function TableDataGrid(props: any) {
  let { salesList } = props;
  let row: any = salesList?.map((item: any, index: any) => {
    const date = new Date(item?.createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return {
      Sr_No: index + 1,
      id: item?._id,
      name: item?.clientId[0]?.firstName,
      createdby: item?.createdBy,
      createdDate: formattedDate,
      businessName: item?.Business?.businessName,
      duration: item?.serviceId?.map((item: any) => item?.serviceId?.duration)?.join(','),
      packagesId: item?.packagesId?.map((item: any) => item?.PackageName)?.join(','),
      membership: item?.membershipId?.map((item: any) => item?.Membership)?.join(','),
      price: item?.netAmount,

      // createdDate:item?.createdAt?.toLocalString(),
      services: item?.serviceId?.map((item: any) => item?.serviceId?.serviceId?.serviceName)?.join(','),
    }
  })
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
