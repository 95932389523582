import React, { useEffect, useMemo, useState } from "react";
import cx from "./index.module.scss";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { FiCamera, FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { doc, getDoc, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdInsertPhoto } from "react-icons/md";
const User = (props: any) => {
  let {
    handleChatSelection,
    loading,
    selectedChat,
    businessData,
    chats,
  } = props;
  const [searchTxt, setSearchTxt] = useState<any>("");
  const [isActive, setIsActive] = useState<any>("");

  async function setUpdateUser() {
    try {
      const res = await getDoc(doc(db, "superadminuser", selectedChat?._id));
      if (!res.exists()) {
        await setDoc(doc(db, "superadminuser", selectedChat?._id), {
          createdAt: serverTimestamp(),
          businessId: selectedChat?._id,
          lastMsg: '',
          type: '',
          isOnline: false,
          isSeen: false,
          messages: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (selectedChat?._id) {
      setUpdateUser()
    }
  }, [selectedChat]);

  const handleItemClick = (item: any) => {
    const idValue = item?._id || '';
    const replacedIdValue = idValue.replace(/-/g, '')
    const modifiedData: any = { ...item, _id: replacedIdValue };
    setIsActive(item?._id);
    handleChatSelection(item);
  };


  function compareDates(date1: any, date2: any) {
    // Create Date objects for each input date
    var d1 = new Date(date1);
    var d2 = new Date(date2);

    // Extract year, month, and day values
    var year1 = d1.getFullYear();
    var month1 = d1.getMonth();
    var day1 = d1.getDate();

    var year2 = d2.getFullYear();
    var month2 = d2.getMonth();
    var day2 = d2.getDate();

    // Compare the dates
    if (year1 === year2 && month1 === month2 && day1 === day2) {
      return 0; // Dates are equal
    } else if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
      return -1; // date1 is earlier than date2
    } else {
      return 1; // date1 is later than date2
    }
  }
  const filterBusiness = useMemo(() => {
    let filteredBusiness = businessData?.filter((business: any) =>
      business?.businessName?.replaceAll(" ", '')?.toLocaleLowerCase()?.includes(searchTxt?.toLocaleLowerCase()?.replaceAll(" ", ''))
    )

    const sortedBusiness = selectedChat ? filteredBusiness : filteredBusiness?.sort((a: any, b: any) => {
      const aUpdate = a?.updateMessage ?? 0;
      const bUpdate = b?.updateMessage ?? 0;
      return bUpdate - aUpdate;
    })

    return sortedBusiness
  }, [businessData, searchTxt, selectedChat]);

  return (
    <>

      <Col className={`${cx.chatList} p-0 ${selectedChat?._id ? "col" : "col-4"}`}>
        <Col md={12}>
          <div className="text-end">
            {/* <HelpButon number={53} type={"help"} className={"btn"} /> */}
          </div>
        </Col>
        <div className={`${cx.chatHeading} justify-content-between gap-2`}>
          <span>Chats</span>
        </div>
        <div className={`${cx.chatSearchBox}`}>
          <FiSearch className={`${cx.searchBtn}`} />
          <input type="search" className="form-control" value={searchTxt}
            placeholder="Search"
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }} />
        </div>
        <ul>
          {loading ? (<li><button className={`${cx.listMenu}`}>
            <div className={`${cx.chatListBody}`}>
              <h5 style={{ color: "#3e8575" }}>
                Fetching...
              </h5>
            </div>
          </button></li>)
            : filterBusiness?.map((item: any) => {
              const chattedBusiness = chats?.find((cb: any) => cb?.businessId?.replaceAll('-', '') === item?._id?.replaceAll('-', ''));
              let timeStamp: any = chattedBusiness?.updateMessage
              let timeDate: any;

              if (timeStamp) {
                timeDate = new Date(timeStamp?.seconds * 1000 + timeStamp?.nanoseconds / 1000000)
              }

              const options: any = {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              };

              const formatter = new Intl.DateTimeFormat('en-US', options);

              const formattedDate = timeDate ? formatter.format(timeDate) : ""
              const date = timeDate ? formattedDate?.split(",")?.[0] : ""
              const time = timeDate ? formattedDate?.split(",")?.[1] : ""
              const checkIfBookingToday = !formattedDate ? false : compareDates(date, new Date()) === 0;

              const dynamicDate = !formattedDate ? "" : checkIfBookingToday ? time : date;
              const firstNameLetter = item?.businessName?.[0]?.toUpperCase();
              const firstLastNameLetter = item?.businessName?.[1]?.toUpperCase();
              return (
                <li>
                  <button
                    className={`${cx.listMenu} ${item?._id === selectedChat?._id ? cx.active : ''}`}
                    onClick={() => handleItemClick(item)}>
                    {item?.profileImage || item?.image?.[0] ?
                      <img src={item?.profileImage || item?.image?.[0] || ''} alt='business-profile' className={`${cx.profileImg}`} />
                      : (
                        <div className={cx.businessIconBox}>
                          <span>{`${firstNameLetter || ''}${firstLastNameLetter || ''}`}</span>
                        </div>
                      )}
                    <div className={`${cx.chatListBody_mainBox}`}>
                      <div className={`${cx.chatListBody}`}>
                        <h5 title={`${item?.firstName || ""}`}>
                          {item?.businessName || ''}
                        </h5>
                        {<span>{dynamicDate}</span>}
                        {/* <p>{item?.allMessage?.length !== 0 && item?.lastMsg === "" ? <MdInsertPhoto /> : item?.lastMsg}</p> */}

                      </div>
                      <p>
                        {chattedBusiness?.type === 'img' ? (
                          <img style={{
                            height: "13px",
                            width: "30px",
                            objectFit: "cover"
                          }}
                            src={chattedBusiness?.lastMsg} alt={item?.businessName} />
                        ) : chattedBusiness?.type === 'text' ? chattedBusiness?.lastMsg : (
                          <div style={{ color: "#fff", background: '#af0d0dde', width: '40px', textAlign: 'center', borderRadius: '5px', margin: '0', fontSize: '11px' }}>{chattedBusiness?.type?.toLocaleUpperCase()}</div>
                        )}
                      </p>
                    </div>
                  </button>
                </li>)

            })}

        </ul>
      </Col>
      {!selectedChat && (
        <Col lg={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
          <div className={`${cx.noAppoint_yet}`}>
            <div className={`${cx.icon}`}>
              <BiMessageSquareDetail />
            </div>
            <h4>Select a conversation from the list to start chatting</h4>
          </div>
        </Col>
      )}
    </>
  );
};

export default User;
