import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiFillEye, AiOutlineEdit } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import HStatus from "../../../components/Forms/HStatus";
import DeletePopup from "../../../components/Modals/DeletePopup";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";
import { MdEdit } from "react-icons/md";
import AddHelpDocumentModel from "../../../components/Modals/AddHelpDocumentModel";
import AddCategoryWebPages from "../../../components/Modals/AddCategoryWebPages";
import Checkbox from "../../../components/Forms/Checkbox";
import Status from "../../../components/Forms/Status";

const StatusButton = (props: any) => {
    let { params, fetchHelpDocuments } = props;
    console.log(params, "param");



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [editshow, setEditShow] = useState(false);
    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);

    const [showDelete, setDeleteShow] = useState(false);
    const handleDeleteClose = () => setDeleteShow(false);
    const handleDeleteShow = () => setDeleteShow(true);


    const deletePackages = async () => {
        const res = await httpRequest(`delete_role/${params?.id}`, "DELETE", null, "withoutToken");

        console.log(res, "lll")
        if (res?.status == true) {
            fetchHelpDocuments && fetchHelpDocuments()
            setTimeout(() => {
                handleDeleteClose()

            }, 1000)

            // window.location.reload();
        } else {
        }
    };
    // const res = await httpRequest("get_role", "get", null, null);

    return (
        <>
            {/* <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>


          </li>

          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={`/settings/add-promotion`}
              state={{permotionId : params?.row?.id}}
            >
              <AiOutlineEdit />
            </NavLink>
          </li>
         
        </ul>
      </div> */}
            <div>
                <ul className={`${tb.actionTable}`}>
                    <li>
                        <NavLink
                            className={`btn ${tb.edit}`}
                            title="Edit"
                            state={{ roleId: params?.row?.id }}
                            to={'/settings/edit-role'}
                        // to={""}
                        >
                            <MdEdit />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={`btn ${tb.delete}`}
                            title="Delete"
                            to="#"
                            onClick={handleDeleteShow}
                        >
                            <AiFillDelete />
                        </NavLink>
                    </li>
                    <li>
                        <Status status={params?.row?.status} api="role_status" id={params?.row?.id}
                            apiMethod="json" getLatest={fetchHelpDocuments} item={params?.row?.role || ''} />
                    </li>
                </ul>
            </div>
            <LocationM show={show} handleClose={handleClose} />
            <DeletePopup show={showDelete} deletePackages={deletePackages} handleDeleteClose={handleDeleteClose} />
        </>
    );
};

const renderCellWithTooltip = (params: any) => (
    <Tooltip title={params?.value}>
        <div>{params?.value}</div>
    </Tooltip>
);

const ViewButton = (props: any) => {
    const { params, fetchHelpDocuments } = props;

    console.log(fetchHelpDocuments, "fetchHelpDocuments")
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <div>
            <ul className={`${tb.actionTable}`}>
                <li>
                    <button
                        className={`btn ${tb.edit}`}
                        title="View"
                        onClick={handleShow}
                    >
                        <AiFillEye />
                    </button>
                </li>
            </ul>
            {show && <AddCategoryWebPages fetchHelpDocuments={fetchHelpDocuments} show={show} handleClose={handleClose} data={params?.row} />}
            {/* {show && <AddHelpDocumentModel fetchHelpDocuments={fetchHelpDocuments} show={show} handleClose={handleClose} data={params?.row} />} */}
        </div>


    );
};

export default function TableGridRole(props: any) {
    let { role, fetchHelpDocuments } = props;

    console.log(fetchHelpDocuments, "lkkkkkk")
    // const { promotionData } = props; 

    const columns = [
        { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },



        {
            field: "role",
            headerName: "Role",
            flex: 2,
            minWidth: 40,
            renderCell: renderCellWithTooltip,
        },
        {
            field: 'criticalRole',
            headerName: 'Critical Role',
            flex: 1,
            minWidth: 70,
            renderCell: (params: any) => (
                <div style={{ marginBottom: '4px' }}>
                    <Checkbox checked={params?.row?.criticalRole} />
                </div>
            ),
        },
        {
            field: "action",
            // headerName: "Action(Activate/Change/Delete)",
            headerName: "Action",
            flex: 1,
            minWidth: 40,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                // Custom comparator for sorting the status (isActive)
                if (v1 === v2) return 0;
                return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
            },
            renderCell: (params: any) => <StatusButton params={params} fetchHelpDocuments={fetchHelpDocuments} />,
        },







    ];
    let row: any = role?.map((item: any, index: any) => {
        return {
            Sr_No: index + 1,
            status: item?.isActive,
            id: item?._id,
            role: item?.role,
            criticalRole: item?.criticalRole,
            action: item?.isActive,
        }   
    })
    return (
        <div style={{ width: "100%" }}>
            <DataGrid
                columns={columns}
                rows={row}
                autoHeight
                pageSize={100}
                rowHeight={48}
                headerHeight={48}
                checkboxSelection
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>
    );
}
