import React, { useEffect, useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { httpRequest } from "../../Apis/commonApis";

const AddTag = (props: any) => {
  let { show, handleClose, getServiceTag, catagoryData } = props;
  const [error, setError] = useState("")
  const [data, setData] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const addServiceTag = async (postData: any) => {
    let data = {
      tags: postData.serviceTag,
      // businessCategoryId: postData.serviceCategory.map((cat: any) => cat.value)
      businessCategoryId: selectedCategories?.map((cat: any) => cat.value),
    }
    let res = await httpRequest(`create_serviceTag`, "post", data, "json");
    console.log(res, "respijj")
    if (res?.status) {
      reset()
      getServiceTag()
      handleClose()
    } else {
      setError(res.message)
    }
  }


  // const categoryOptions = catagoryData?.map((category: any) => ({
  //   value: category.id,
  //   label: category.BusinessCategoryName,
  // }));

  // const categoryOptions = catagoryData?.[0]?.label ? catagoryData : catagoryData?.map((tag: any) => {
  //   return { value: tag?.id, label: tag?.BusinessCategoryName };
  // });

  const handleCategoryChange = (selectedOptions: any) => {
    setSelectedCategories(selectedOptions);
  };

  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Add Tag</h5>
          </Col>
          <Row>
            <Col md={12}>
              <Row className="w-100 mx-auto">
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Tag <span className="text-danger">*</span>
                    </Form.Label>
                    <input type="text" className="form-control"
                      {...register("serviceTag", {
                        required: true,
                        pattern: /^[a-zA-Z ]+$/,
                        min: 1,
                      })}
                    />
                    {errors?.serviceTag && (
                      <p className="errorMessage">
                        {errors.serviceTag.type === "required"
                          ? "This field is required"
                          : errors.serviceTag.type === "pattern"
                            ? "Alphabets only"
                            : errors.serviceTag.type === "min"
                              ? "Minimum one character is necessary."
                              : null}
                      </p>
                    )}
                    {error && (
                      <p className="errorMessage">{error}</p>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Service Category <span className="text-danger">*</span>
                    </Form.Label>
                    {/* <select
                      className="form-control"
                      {...register("serviceCategory", { required: true })}
                     
                    >
                      <option value="">Select Category</option>
                      {catagoryData?.map((category:any) => (
                        <option key={category.id} value={category.id}>
                          {category.BusinessCategoryName
}
                        </option>
                      ))}
                    </select> */}

                {/* {errors?.serviceCategory && (
                      <p className="errorMessage">This field is required</p>
                    )}
                  </Form.Group>
                </Col> */}

                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Business Category <span className="text-danger">*</span>
                    </Form.Label>
                    {/* <Select
                      options={categoryOptions}
                      closeMenuOnSelect={false}
                      value={selectedCategories}
                      onChange={handleCategoryChange}
                      isMulti
                      placeholder="---Select---"
                    /> */}
                    <Select
                      options={catagoryData}
                      closeMenuOnSelect={false}
                      // defaultValue={defaultVal ? defaultVal : null}
                      value={selectedCategories}
                      isMulti={true}
                      onChange={handleCategoryChange}
                    // placeholder="---Select---"
                    // isDisabled={false}
                    />
                    {/* {!selectedCategories.length && (
                      // <p className="errorMessage">This field is required</p>
                    )} */}
                  </Form.Group>
                </Col>

                <Col lg={12} className="mt-2">
                  <Button className={`${st.btnDisabled}`} onClick={handleSubmit(addServiceTag)}
                  >Save</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTag;
