import React, { useEffect, useState } from "react";
import st from "../../style.module.scss";
import { httpRequest } from "../../Apis/commonApis";
import { ActiveDataGrid } from "../Modals/ActiveDataGrid";

const OnOffSwitch = (props: any) => {
  const { checked, disabled = false, handleChange, actionType } = props
  // console.log(checked, "checked");


  const [showModal, setShowModal] = useState(false);

  const [pendingChange, setPendingChange] = useState<any>(null);


  const onSwitchChange = (e: any) => {
    setPendingChange({
      checked: e.target.checked,

    });

    setShowModal(true);
  };
  const handleActiveWithPopup = async () => {
    if (pendingChange) {

      await handleChange({
        target: {
          checked: pendingChange.checked,
        },
      });

      setPendingChange(null);
    }
    // Close the modal
    setShowModal(false);
  };


  const handleActiveDeactiveClose = () => {
    setShowModal(false);

    setPendingChange(null);
  };


  return (
    <>
      <label className={`${st.switch} ${st.onOff_switch}`}>
        <input
          className={`${st.switchInput}`}
          type="checkbox"
          checked={checked}
          // onChange={handleChange}
          onChange={onSwitchChange}

          disabled={disabled}
        />
        <span
          className={`${st.switchLabel}`}
          data-on="Active"
          data-off="Deactive"
        ></span>
        <span className={`${st.switchHandle}`}></span>
      </label>
      <ActiveDataGrid
        show={showModal}
        handleActiveDeactiveClose={handleActiveDeactiveClose}
        handleActiveWithPopup={handleActiveWithPopup}
        actionType={actionType}
        checked={pendingChange?.checked}

      />
    </>

  );
};

export default OnOffSwitch;
