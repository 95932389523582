import React, { useEffect, useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import Compressor from 'compressorjs';
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import crossRed from "../../images/crossRed.svg";
import uploadIcon from "../../images/uploadIcon.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { httpRequest } from "../../Apis/commonApis";
import { useForm } from "react-hook-form";

const AddCategory = (props: any) => {
  let { show, handleClose, params, Add, editData, getCatagory } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [BusinessCategoryName, setBusinessCategoryName] = useState("");
  const [icon, setIcon] = React.useState<any>();
  const [image, setImage] = useState<any>();
  const [imageagain, setImageAgain] = React.useState<any>('');
  const [imagereagain, setImagereAgain] = React.useState<any>('');
  const [imageError, setimageError] = useState(false);
  const [updateImage, setUpdateImage] = React.useState<any>(params?.Icon);
  const [updateImg, setUpdateImg] = React.useState<any>(params?.image);
  const [error, setError] = useState("");

  const handleCompressedUpload = (e:any) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        return compressedResult
      },
    });
  };

  const handleSecondImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: any = e.target.files;
    if (!fileList) return;
    let display = URL.createObjectURL(fileList[0]);
    setImage(display);
    setImagereAgain(fileList[0])
  };

  setTimeout(function () {
    setimageError(false);
  }, 5000);

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList: any = e.target.files;
    if (!fileList) return;
    let display = URL.createObjectURL(fileList[0]);
    setIcon(display);
    setImageAgain(fileList[0]);
    setUpdateImage(fileList[0])
  };
  const createCatagory = async (data: any) => {
    let formData = new FormData();
    formData.append("BusinessCategoryName", data.BusinessCategoryName);
    formData.append("Icon", imageagain === 'delete' ? "" : imageagain);
    formData.append("image", imagereagain === 'delete' ? "" : imagereagain);
    const path = editData ? `update_Catagory/${editData?._id}` : "create_Catagory";
    const method = editData ? 'PATCH' : 'POST'
    const res = await httpRequest(
      path,
      method,
      formData,
      "formdata"
    );
    if (res.status === true) {
      getCatagory()
      handleClose()
    } else {
      setError(res.message)
    }
  };
  useEffect(() => {
    if (editData) {
      setValue('BusinessCategoryName', editData?.BusinessCategoryName);
      setIcon(editData?.Icon)
      setImage(editData?.image)
      setImageAgain(editData?.Icon)
      setImagereAgain(editData?.image)
    }
  }, [editData])

  const removeImage = (type: string) => {
    if (type === 'icon') {
      setIcon('');
      setImageAgain('delete')
    } else {
      setImage('');
      setImagereAgain('delete')
    }
  }

  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Add Business Category</h5>
          </Col>
          <Row className="justify-content-center">
            <Col md={4} lg={4}>
              <div className={`${st.uploadForm} me-1 mb-3`}>
                <div className={`${st.fileUpload} position-relative`} >
                  {icon && <img src={crossRed} alt='icon' onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeImage('icon')
                  }} style={{ position: 'absolute', top: '3px', right: '5px', background: '#fff', padding: '4px', borderRadius: '50%' }} />}
                  {icon && <label htmlFor="upload" style={{ position: 'absolute', bottom: '3px', right: '5px', height: '40px', width: '40px' }}>
                    {<img src={uploadIcon} alt='icon' style={{ background: '#fff', padding: '4px', borderRadius: '50%', height: '100%', width: '100%' }} />}
                    <input
                      id="upload"
                      type="file"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        handleImageChange(event);
                      }}
                    />
                  </label>}
                  {!icon && <input
                    type="file"
                    onChange={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      handleImageChange(event);
                    }}
                  />}
                  {icon ? (
                    <img src={icon} className={`${st.icon}`} alt="Icon" />
                  ) : (
                    <img src={uploadFile} className={`${st.icon}`} alt="Icon" />
                  )}
                </div>
                {imageError && (
                  <p className="errorMessage">This field is required</p>
                )}
                <Form.Label>
                  Upload Icon<span className="text-danger">*</span>
                </Form.Label>
              </div>
            </Col>


            <Col md={4} lg={4}>
              <div className={`${st.uploadForm} me-1 mb-3`}>
                <div className={`${st.fileUpload} position-relative`} >
                  {image && <img src={crossRed} alt='icon' onClick={(e:any) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                  }} style={{ position: 'absolute', top: '3px', right: '5px', background: '#fff',zIndex:'10' , padding: '4px', borderRadius: '50%' }} />}
                  <input
                    type="file"
                    onChange={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleSecondImageChange(event);
                    }}
                  />
                  {!image ? (
                    <img src={uploadFile} className={`${st.icon}`} alt="Icon" />
                  ) : (
                    <img src={image} className={`${st.icon}`} alt="Icon" />
                  )}
                </div>
                <Form.Label>
                  Upload Image<span className="text-danger">*</span>
                </Form.Label>
              </div>
            </Col>

            <Col md={12} lg={12}>
              <Row>
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      Business Category<span className="text-danger">*</span>
                    </Form.Label>
                    <input
                      type="text"
                      defaultValue={params?.category}
                      className="form-control"
                      {...register("BusinessCategoryName", {
                        required: true,
                        pattern: /^[a-zA-Z ]+$/,
                        min: 1,
                      })}
                    />

                    {errors?.BusinessCategoryName?.type === "required" && (
                      <p className={"errorMessage"}>This field is required</p>
                    )}
                    {errors?.BusinessCategoryName?.type === "pattern" && (
                      <p className={"errorMessage"}> Alphabets only</p>
                    )}
                    {errors?.BusinessCategoryName?.type === "min" && (
                      <p className={"errorMessage"}>
                        {" "}
                        minimum one character is necessary .
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <div>{error}</div>
                <Col lg={12} className="mt-2">
                  <Button className={`${st.btnDisabled}`}
                    onClick={handleSubmit(createCatagory)}
                  >
                    Save
                  </Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCategory;
