import React, { useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import userIcon from "../../images/iconUser.svg";
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { httpRequest } from "../../Apis/commonApis";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const BusinessUserM = (props: any) => {
  let { show, handleClose, userData, Business } = props;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [businessName, setbusinessName] = useState(userData?.businessName);
  const [firstName, setfirstName] = useState(userData?.fName);
  const [lastName, setlastName] = useState(userData?.lName);
  const [email, setemail] = useState(userData?.email);
  const [mobile, setmobile] = useState(userData?.phoneNumber);
  const [usermobile, setusermobile] = useState("");
  const [error,seterror] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [phoneError,setPhoneError] = useState(false);
  const[countryName,setcountryName] = useState("")
  setTimeout(() => {
    seterror("")
   }, 5000);

  const handleOnChange = (value: any, selectedCountry: any) => {
   
    
    let mobileNumberWithoutCountryCode = value.replace(selectedCountry?.dialCode, '')
    if (Business !== undefined) {
      setusermobile(mobileNumberWithoutCountryCode);
      setcountryCode(selectedCountry?.dialCode);
      setcountryName(selectedCountry?.name)
    } else {
      setmobile(mobileNumberWithoutCountryCode);
      setcountryCode(selectedCountry?.dialCode);
      setcountryName(selectedCountry?.name)
    }
  };
  const updatebusinessUser = async () => {
    if (mobile.length < 5) {
      setPhoneError(true);
      return;
    }
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
    };
    const res = await httpRequest(`businessuser_update/${userData?.id}`, "PATCH",data, "json");
    if (res?.status == true) {
      seterror(res?.message)
      window.location.reload();
    } else {
      seterror(res?.message)
    }
  };
  const addnewbusiness_user = async (postData:any) => {
    if (usermobile.length < 10) {
      setPhoneError(true);
      return;
    }
    let data = {
      firstName: postData.firstName,
      lastName: postData.lastName,
      email: postData.email,
      mobile: usermobile,
      stepCompleted: 5,
      country:countryName,
      emailVerified: true,
      dialCode: countryCode,
    };
    const res = await httpRequest("addnewbusiness_user", "POST", data, "json");
    if (res?.status == true) {
      window.location.reload();
      seterror("")
    } else {
      seterror(res?.message)
    }
  };
  
  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Add Business User</h5>
          </Col>
          <Row>
            <Col md={12} lg={12}>
              <Row>
                <Col lg={6}>
                  <Form.Group className={`${st.formField} ${st.inside}`}>
                    <Form.Label>First Name</Form.Label>
                    <img src={userIcon} alt="user" className={st.insideImage} />
                     {Business !== undefined ? (
                      <div>
                        <input
                          type="text"
                          defaultValue={userData?.lName}
                          className="form-control"
                          {...register("firstName", {
                            required: true,
                            pattern: /^[a-zA-Z ]+$/,
                            min: 1,
                          })}
                        />
                        {errors?.firstName && (
                          <p className="errorMessage">
                            {errors.firstName.type === "required"
                              ? "This field is required"
                              : errors.firstName.type === "pattern"
                              ? "Alphabets only"
                              : errors.firstName.type === "min"
                              ? "Minimum one character is necessary."
                              : null}
                          </p>
                        )}
                      </div>
                    ) : (
                      <input
                      type="text"
                      defaultValue={userData?.fName}
                      className="form-control"
                      onChange={(e: any) => {
                        setfirstName(e?.target?.value);
                      }}
                    />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className={`${st.formField} ${st.inside}`}>
                    <Form.Label>Last Name</Form.Label>
                    <img src={userIcon} alt="user" className={st.insideImage} />
                    {Business !== undefined ? (
                      <div>
                        <input
                          type="text"
                          defaultValue={userData?.lName}
                          className="form-control"
                          {...register("lastName", {
                            required: true,
                            pattern: /^[a-zA-Z ]+$/,
                            min: 1,
                          })}
                        />
                        {errors?.lastName && (
                          <p className="errorMessage">
                            {errors.lastName.type === "required"
                              ? "This field is required"
                              : errors.lastName.type === "pattern"
                              ? "Alphabets only"
                              : errors.lastName.type === "min"
                              ? "Minimum one character is necessary."
                              : null}
                          </p>
                        )}
                      </div>
                    ) : (
                      <input
                        type="text"
                        defaultValue={userData?.lName}
                        className="form-control"
                        onChange={(e) => {
                          setlastName(e.target.value);
                        }}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>Email</Form.Label>
                    {Business !== undefined ? (
                      <div>
                        <input
                          type="text"
                          defaultValue={userData?.email}
                          className="form-control"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                        {errors?.email?.type === "pattern" && (
                        <p className={"errorMessage"}>Invalid email address</p>
                      )}
                      {errors?.email?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                        
                      </div>
                    ) : (
                      <input
                      type="text"
                      defaultValue={userData?.email}
                      className="form-control"
                      onChange={(e: any) => {
                        setfirstName(e?.target?.value);
                      }}
                    />
                    )}

                   
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className={`${st.formField} ${st.inside}`}>
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                            country={"in"}
                            onChange={handleOnChange}
                            value={userData?.phoneNumber}
                            inputProps={{
                              name: "Phone Number",
                              required: true,
                              autoFocus: false,
                            }}
                            defaultErrorMessage="It doesn't work, why?"
                          />
                          {phoneError && (
                          <p className="errorMessage">Invalid phone number</p>
                        )}
                  </Form.Group>
                </Col>
                <div>{error}</div>
                <Col lg={12} className="mt-2">
                
                  <Button
                    className={`${st.btnDisabled}`}
                    onClick={
                      Business !== undefined
                        ? handleSubmit(addnewbusiness_user)
                        : updatebusinessUser
                    }
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessUserM;
