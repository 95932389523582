import React, { useEffect, useState } from "react";
import cx from "./Promotion.module.scss";
import pm from "./Tablestyle.module.scss";

// import st from "../../style.module.scss";
import st from "../../../style.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
// import { basicInfo, basicInfoA } from "../../../assest";
import basic from "../../../images/basicInfoTab.svg";
import plusV2 from "../../../images/plusV2.svg";
import crossRed from "../../../images/crossRed.svg";
import { httpRequest } from "../../../Apis/commonApis";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { disablePastDate, timeValues } from "../../../constant";
import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import { getMinDate } from "../../../constants/scheduler";

// import BackArrrow from "../../../components/Business/Buttons/BackArrow";

const AddCategory = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const [promotion, setPromotion] = useState<any>([]);
  const [errorTrig, setErrorTrig] = useState<boolean>(false)

  const [settingData, setSettingData] = useState<any>({
    LimitUsePerClient: { active: false, maxUse: "" },
    LimitNumberOfUses: {
      active: false,
      maxUser: ""
    },
  });


  const onSubmit = async (data: any) => {
    if(watch("maxUserCheck") && settingData?.LimitNumberOfUses?.maxUser === ""){
      setErrorTrig(true)
      setTimeout(() =>{
        setErrorTrig(false)
      },3000)
      return
    }
    if( watch("maxClientCheck") && settingData?.LimitUsePerClient?.maxUse === ""){
      setErrorTrig(true)
      setTimeout(() =>{
        setErrorTrig(false)
      },3000)
      return
    }



    const formData = new FormData();
    formData.append('promotionName', data?.promotionName);
    formData.append('promotionType', data?.promotionType);
    formData.append('discountCode', data?.discountCode || "");

    formData.append('tagline', data?.tagline || "");
    formData.append('manageTagline', JSON.stringify(data?.manageTagline));

    formData.append('bookingdateFrom', data?.bookingdateFrom || "");
    formData.append('bookingdateTo', data?.bookingdateTo);
    formData.append('bookingstartTimeFrom', data?.bookingstartTimeFrom || "");
    formData.append('bookingendTimeTo', data?.bookingendTimeTo || "");
    formData.append('selldateFrom', data?.selldateFrom || "");
    // formData.append('selldateFrom', data?.selldateFrom);
    formData.append('selldateTo', data?.selldateTo || "");
    formData.append('sellstartTimeFrom', data?.sellstartTimeFrom || "");
    formData.append('sellendTimeTo', data?.sellendTimeTo || "");
    formData.append('details', briefAbout || "");
    formData.append('message', data?.message || "");


    formData.append("frequency", data?.frequency || "")
    formData.append("unit", data?.unit || "")
    formData.append("isRequiredLastMinuteDeal", JSON.stringify(data?.isRequiredLastMinuteDeal))

    formData.append("managedPromotionName", JSON.stringify(data?.managedPromotionName))
    formData.append("managedPromoCode", JSON.stringify(data?.managedPromoCode))
    formData.append("managedBookingDate", JSON.stringify(data?.managedBookingDate))
    formData.append("managedSellDate", JSON.stringify(data?.managedSellDate))


    formData.append("maxClientCheck", JSON.stringify(data?.maxClientCheck))
    formData.append("maxUserCheck", JSON.stringify(data?.maxUserCheck))
    formData.append("promotionProvidesCategoryCheck", JSON.stringify(data?.promotionProvidesCategoryCheck))
    formData.append("newCustomerCheck", JSON.stringify(data?.newCustomerCheck))
    formData.append("isActive", JSON.stringify(true))

    const limitUser = {
      active: settingData?.LimitNumberOfUses.active,
      maxUser: settingData?.LimitNumberOfUses?.maxUser
    }
    formData.append("LimitNumberOfUses", JSON.stringify(limitUser));
    const limit = settingData?.LimitUsePerClient?.maxUse || 0;
    formData.append("LimitUsePerClient", limit)
    formData.append("forFirstTime", JSON.stringify(data?.forFirstTime === "newUser"))
    formData.append("promotionProvidesCategory", Array.isArray(data?.promotionProvidesCategory) ? data?.promotionProvidesCategory?.join(',') : data?.promotionProvidesCategory);


    for (let i = 0; i < image.length; i++) {
      formData.append('image', image[i]);
    }

    let res;
    if (location?.state?.permotionId) {
      res = await httpRequest(`updatePromotion/${location?.state?.permotionId}`, "PATCH", formData, "formdata");
    } else {
      res = await httpRequest(`createPromotion`, "POST", formData, "formdata");
    }

    if (res?.status === true) {

      navigate('/settings/promotion');
    } else {
      console.error("Failed to create/update promotion", res);
    }
  }

  const [briefAbout, setBriefAbout] = useState<any>('');
  const [brieferr, setBrieferr] = useState("");
  const [imageProfile, setProfileImage] = useState<any>([]);
  const [image, setImage] = useState<any>([]);
  const [existImage, setExistImage] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["link"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const handleAbout = (newContent: string, type: string) => {
    setBriefAbout(newContent)
    setBrieferr("")
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const multipleImages: any[] = [];
      for (let i = 0; i < files.length; i++) {
        multipleImages.push(files[i]);
      }

      if (multipleImages.length !== 0) {
        const imageUrls = multipleImages.map((data: File) => {
          return { blob: URL.createObjectURL(data), original: data };
        });

        setProfileImage([...imageProfile, ...imageUrls]);
        setImage([...image, ...multipleImages]);
      }
    }
  };


  const handleDeleteImage = (img: any, i: any, type: any) => {
    if (type === "update") {
      setExistImage(existImage?.filter((image: any, index: any) => {
        return index !== i
      }))
    }
    const filterBlob = imageProfile?.filter((blob: any) => {
      return blob !== img
    })

    const filterImage = image?.filter((image: any) => {
      return image !== img.original
    });
    setProfileImage(filterBlob);
    setImage(filterImage)
  }

  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };


  const getData = async () => {
    const res: any = await httpRequest(`getPromotion?_id=${location?.state?.permotionId}`, "get", null, null);
    if (res?.status == true) {
      setPromotion(res?.data?.[0]);
    }
  };

  useEffect(() => {
    if (promotion?.length !== 0) {
      setValue("promotionName", promotion?.promotionName);
      setValue("discountCode", promotion?.discountCode || "");
      setValue("isPlatformDependent", promotion?.isPlatformDependent ? "wellnezza" : "business");
      setValue("bookingdateFrom", promotion.bookingdateFrom);
      setValue("bookingendTimeTo", promotion.bookingendTimeTo);
      setValue("bookingstartTimeFrom", promotion.bookingstartTimeFrom);
      setValue("bookingdateTo", promotion.bookingdateTo);
      setValue("bookingstartTimeFrom", promotion?.bookingstartTimeFrom);
      setValue("bookingendTimeTo", promotion.bookingendTimeTo);
      setValue("selldateFrom", promotion?.selldateFrom);
      setValue("selldateTo", promotion.selldateTo);
      setValue("sellstartTimeFrom", promotion.sellstartTimeFrom);
      setValue("sellendTimeTo", promotion.sellendTimeTo);
      setBriefAbout(promotion.details);
      setValue("promotionType", promotion?.promotionType)
      setValue("frequency", promotion?.frequency)
      setValue("unit", promotion?.unit)
      setValue("isRequiredLastMinuteDeal", promotion?.isRequiredLastMinuteDeal)

      setValue('tagline', promotion?.tagline);
      setValue('manageTagline', promotion?.manageTagline);

      setValue("managedPromotionName", promotion?.managedPromotionName)
      setValue("managedPromoCode", promotion?.managedPromoCode)
      setValue("managedBookingDate", promotion?.managedBookingDate)
      setValue("managedSellDate", promotion?.managedSellDate)
      setExistImage(promotion?.image);

      const settingData = {
        LimitUsePerClient: { active: promotion?.LimitUsePerClient !== "" || promotion?.LimitUsePerClient !== 0, maxUse: promotion?.LimitUsePerClient === 0 ? "" :  promotion?.LimitUsePerClient},
        LimitNumberOfUses: promotion?.LimitNumberOfUses
      }

      setSettingData(settingData);

      setValue("forFirstTime", promotion?.forFirstTime ? "newUser" : "")
      setValue("promotionProvidesCategory", promotion?.promotionProvidesCategory ? promotion?.promotionProvidesCategory?.split(',') || [] : [])

      setValue("maxClientCheck", promotion?.maxClientCheck)
      setValue("maxUserCheck", promotion?.maxUserCheck)
      setValue("promotionProvidesCategoryCheck", promotion?.promotionProvidesCategoryCheck)
      setValue("newCustomerCheck", promotion?.newCustomerCheck)

    }
  }, [promotion])



  useEffect(() => {
    getData()
  }, [location?.state?.permotionId])



  const singleValue = watch("promotionType")

  const allPermotion: any = ["Regular", "Platform", "Off Peak Hours", "Last minute offer",]

  useEffect(() => {
    if (!watch("promotionProvidesCategoryCheck")) {
      setValue("promotionProvidesCategory", [])
    }
  }, [watch("promotionProvidesCategoryCheck")])

  useEffect(() => {
    if (!watch("newCustomerCheck")) {
      setValue("forFirstTime", "")
    }
  }, [watch("newCustomerCheck")])


  useEffect(() => {
    if (!watch("maxClientCheck")) {
      setSettingData({
        ...settingData, LimitUsePerClient: { active: false, maxUse: "" },
      })
    }
  }, [watch("maxClientCheck")])


  useEffect(() => {
    if (!watch("maxUserCheck")) {
      setSettingData({
        ...settingData, LimitNumberOfUses: {
          active: false,
          maxUser: ""
        },
      })
    }
  }, [watch("maxUserCheck")])



  return (
    <>
      <section className={`${pm.pageWrapper}`} style={{ overflowY: 'auto', height: '100%', paddingBottom: '80px' }}>
        <div className={`${pm.pageTitle}`}>
          <div className={`${pm.titleInfo}`}>

            {/* <h5> Add Promotion</h5> */}
            {promotion?.length !== 0 ? "Edit Promotion" : "Add Promotion"}
          </div>
          <div className={`${pm.rightInfo}`}></div>
        </div>

        <div className={`${pm.tabbingBox} ${pm.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basic} alt="info" className={`${pm.defalut}`} />
                  {/* <img src={basic} alt="info" className={`${pm.active}`} /> */}
                  {/* Basic Info */}
                  Details
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Row>
                    {/* <div className="mt-3"> */}
                    <Col md={12}>
                      <div className={`${pm.formBox} ${pm.locationSelect}`}>
                        <h3>About</h3>
                        <p>Main Data</p>
                      </div>
                    </Col>
                  </Row>
                  <Col lg={3} md={3}>
                    <div className={`${pm.formBox}`}>
                      <label className="form-label">Type of Promotion</label>
                      <select
                        className="form-select"
                        {...register("promotionType", { required: true })}
                      // disabled={editPromotionData?.promotionName}
                      >
                        <option value="">Select</option>
                        {allPermotion?.map((item: any, i: any) => {
                          return (
                            <option value={item} key={i}>{item}</option>
                          )
                        })}
                        {/* <option value="Last minute offer">Last-minute offer</option>
                       <option value="Dynamic Pricing">Dynamic Pricing</option> */}
                      </select>
                      {errors?.promotionType?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  {singleValue === "Last minute offer" ? (
                    <>
                      <Col lg={2} md={2}>
                        <div className={`${pm.formBox}`}>
                          <label className="form-label">Before Booking</label>
                          <input
                            type="Number"
                            // className={`${pm.promotionForm} ${trigger && settingData?.discountCode?.active && settingData?.discountCode?.code === "" ? pm.error : ""} form-control`}
                            className={` form-control`}
                            placeholder="Before Booking"
                            {...register("unit", { required: singleValue === "Last minute offer" })}
                          />
                          {errors?.unit?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col lg={2} md={2}>
                        <div className={`${pm.formBox}`}>
                          <label className="form-label">Period Type</label>
                          <select className="form-select" {...register("frequency", { required: singleValue === "Last minute offer" })} >
                            <option value="">select</option>
                            {/* <option value="Days">Days</option> */}
                            <option value="Hours">Hours</option>
                            <option value="Minutes">Minutes</option>
                          </select>
                          {errors?.frequency?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md={1}>
                        <div className={`${pm.formBox}`}>
                          {/* <label className="form-label opacity-0">Managed by Platform</label> */}
                          <label className={`d-block ${pm.checkbox}`}>
                            <input type="checkbox" {...register("isRequiredLastMinuteDeal")} />
                            <span className={`${pm.checkmark}`}></span>
                          </label>
                        </div>
                      </Col>
                      <Col lg={4} md={4}></Col>
                    </>) : <Col md={9}></Col>}

                  <Col md={3}>
                    <div className={`${pm.formBox}`}>
                      <label className="form-label">Promotion Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        {...register("promotionName", { required: true })}
                      />
                      {errors.promotionName && <p className="errorMessage">This field is required</p>}
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className={`${pm.formBox}`}>
                      <label className="form-label">Promo Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Promo code"
                        {...register("discountCode")}
                      />
                      {errors.discountCode && <p className="errorMessage">This field is required</p>}
                    </div>
                  </Col>

                  <Col md={1}>
                    <div className={`${pm.formBox}`}>
                      {/* <label className="form-label opacity-0">Managed by Platform</label> */}
                      <label className={`d-block ${pm.checkbox}`}>
                        <input type="checkbox" {...register("managedPromoCode")} />
                        <span className={`${pm.checkmark}`}></span>
                      </label>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${pm.formBox}`}>
                      <label className="form-label">Promotion tag line</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Promotion tag line"
                        {...register("tagline", {
                          required: false,
                          maxLength: {
                            value: 20,
                            message: "Tagline cannot exceed 20 characters"
                          }
                        })}
                      />
                      {errors?.tagline?.type === "maxLength" && (
                        <p className={"errorMessage"}>Tagline cannot exceed 20 characters</p>
                      )}
                    </div>
                  </Col>

                  <Col md={1}>
                    <div className={`${pm.formBox}`}>
                      {/* <label className="form-label opacity-0">Managed by Platform</label> */}
                      <label className={`d-block ${pm.checkbox}`}>
                        <input type="checkbox"  {...register("manageTagline")} />
                        <span className={`${pm.checkmark}`}></span>
                      </label>
                    </div>
                  </Col>

                  {singleValue !== "Last minute offer" && <Row>
                    <Col md={12}>
                      <div className={`${pm.line_bottom} mt-3`}>
                        <h5>Sale creation date</h5>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className={`${pm.formBox}`}>
                        <label className="form-label">Date From</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                          min={getMinDate(promotion?.length === 0)}
                          {...register("selldateFrom", { required: watch("managedSellDate") })}
                        />
                        {errors.selldateFrom && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className={`${pm.formBox} `}>
                        <label className="form-label" htmlFor="startTime">Start Time</label>
                        <select className="form-select" {...register("sellstartTimeFrom", { required: watch("managedSellDate") })}>
                          <option value="">Select</option>
                          {timeValues.map((time, index) => (
                            <option key={index} value={time.value}>{time.label}</option>
                          ))}
                        </select>
                        {errors.sellstartTimeFrom && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className={`${pm.formBox}`}>
                        <label className="form-label">Date To</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                          min={watch('selldateFrom', disablePastDate())}
                          {...register("selldateTo", { required: watch("managedSellDate") })}
                        />
                        {errors.selldateTo && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className={`${pm.formBox} `}>
                        <label className="form-label" htmlFor="endTime">End Time</label>
                        <select className="form-select" {...register("sellendTimeTo", { required: watch("managedSellDate") })}>
                          <option value="">Select</option>
                          {timeValues.map((time, index) => (
                            <option key={index} value={time.value}>{time.label}</option>
                          ))}
                        </select>
                        {errors.sellendTimeTo && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className={`${pm.formBox}`}>
                        {/* <label className="form-label opacity-0">Managed by Platform</label> */}
                        <label className={`d-block ${pm.checkbox}`}>
                          <input type="checkbox" {...register("managedSellDate")} />
                          <span className={`${pm.checkmark}`}></span>
                        </label>
                      </div>
                    </Col>

                  </Row>}
                  <Row>
                    <Col md={12}>
                      <div className={`${pm.line_bottom} mt-3`}>
                        <h5>Booking creation date</h5>
                      </div>
                    </Col>
                    <Col md={3}>
                      {/* <div className={`${pm.formBox}`}>
                        <label className="form-label">Date Form</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                        />
                      </div> */}
                      <div className={`${pm.formBox}`}>
                        <label className="form-label">Date From</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                          min={getMinDate(promotion?.length === 0)}
                          {...register("bookingdateFrom", { required: watch("managedBookingDate") })}
                        />
                        {errors.bookingdateFrom && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={2}>
                      {/* <div className={`${pm.formBox} `}>
                        <label className="form-label" htmlFor="date">Start Time
                        </label>
                        <select className="form-select">
                          <option value="10:00 AM">Select</option>
                        </select>
                      </div> */}
                      <div className={`${pm.formBox} `}>
                        <label className="form-label" htmlFor="startTime">Start Time</label>
                        <select className="form-select" {...register("bookingstartTimeFrom", { required: watch("managedBookingDate") })}>
                          <option value="">Select</option>
                          {timeValues.map((time: any, index: number) => (
                            <option key={index} value={time.value}>{time.label}</option>
                          ))}
                        </select>
                        {errors.bookingstartTimeFrom && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className={`${pm.formBox}`}>
                        <label className="form-label">Date To</label>
                        {/* <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                        /> */}
                        <input
                          type="date"
                          className="form-control"
                          placeholder="name"
                          min={watch('bookingdateFrom', disablePastDate())}
                          {...register("bookingdateTo", { required: watch("managedBookingDate") })}
                        />
                        {errors.bookingdateTo && <p className="errorMessage">This field is required</p>}
                      </div>

                    </Col>
                    <Col md={2}>
                      <div className={`${pm.formBox} `}>
                        <label className="form-label" htmlFor="endTime">End Time</label>
                        <select className="form-select" {...register("bookingendTimeTo", { required: watch("managedBookingDate") })}>
                          <option value="">Select</option>
                          {timeValues.map((time, index) => (
                            <option key={index} value={time.value}>{time.label}</option>
                          ))}
                        </select>
                        {errors.bookingendTimeTo && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className={`${pm.formBox}`}>
                        {/* <label className="form-label opacity-0">Managed by Platform</label> */}
                        <label className={`d-block ${pm.checkbox}`}>
                          <input type="checkbox" {...register("managedBookingDate")} />
                          <span className={`${pm.checkmark}`}></span>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={10}>
                      <div className={`${pm.formBox} ${pm.editorBox}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <label htmlFor="Details">Details</label>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <div className={`${pm.editorContainer}`}>
                                <ReactQuill

                                  value={briefAbout}
                                  onChange={(newContent) =>
                                    handleAbout(newContent, "details")
                                  }
                                  modules={modules}
                                />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        {/* {briefAbout && briefAbout === "" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className={`${pm.line_bottom} mt-3`}>
                        <h5>Promotion value and limits</h5>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={6} md={6}>
                      <div className={`${pm.formBox}`}>
                        <div className={'d-flex'}>
                          <label className="form-label" style={{ fontSize: '15px', display: "flex", gap: "10px" }}>Promotion Available to :
                            <label className={`d-block ${pm.checkbox} m-0`}>
                              <input type="checkbox" {...register("promotionProvidesCategoryCheck")} />
                              <span className={`${pm.checkmark}`}></span>
                            </label>

                          </label>

                        </div>

                        <ul className={`${pm.listUl}`}>
                          <li>
                            <label className={`${pm.checkbox}`}>
                              <input
                                type="checkbox"
                                value={"male"}
                                {...register("promotionProvidesCategory", { required: watch("promotionProvidesCategoryCheck") })}
                                disabled={!watch("promotionProvidesCategoryCheck")}

                              />
                              <span className={`${pm.checkmark}`}></span> &nbsp; Male
                            </label>
                          </li>
                          <li>
                            <label className={`${pm.checkbox}`}>
                              <input
                                type="checkbox"
                                value={"female"}
                                {...register("promotionProvidesCategory", { required: watch("promotionProvidesCategoryCheck") })}
                                disabled={!watch("promotionProvidesCategoryCheck")}
                              />
                              <span className={`${pm.checkmark}`}></span> &nbsp; Female
                            </label>
                          </li>
                          <li>
                            <label className={`${pm.checkbox}`}>
                              <input
                                type="checkbox"
                                value={"anyone"}
                                {...register("promotionProvidesCategory", { required: watch("promotionProvidesCategoryCheck") })}
                                disabled={!watch("promotionProvidesCategoryCheck")}
                              />
                              <span className={`${pm.checkmark}`}></span> &nbsp; Everyone
                            </label>
                          </li>
                        </ul>
                        {errors?.promotionProvidesCategory?.type === "required" && <p className="errorMessage">This field is required</p>}
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className={`${pm.formBox}`}>
                        <label className="form-label" style={{ fontSize: '15px', display: "flex", gap: "10px" }}>Promotion Available to :
                          <label className={`d-block ${pm.checkbox} m-0`}>
                            <input type="checkbox" {...register("newCustomerCheck")} />
                            <span className={`${pm.checkmark}`}></span>
                          </label>
                        </label>
                        <ul className={`${pm.listUl}`}>
                          <li style={{ lineHeight: "9px" }}>
                            <label className={`${pm.checkbox}`} style={{ marginTop: '16px' }}>
                              <input
                                type="checkbox"
                                value={"newUser"}
                                {...register("forFirstTime", { required: watch("newCustomerCheck") })}
                                disabled={!watch("newCustomerCheck")}
                              />
                              <span style={{ top: "-11px" }} className={`${pm.checkmark}`}></span> &nbsp; Only For New Customers
                            </label>
                          </li>

                        </ul>
                      </div>
                        {errors?.forFirstTime?.type === "required" && <p className="errorMessage">This field is required</p>}
                    </Col>


                  </Row>


                  <Row>
                    <Col lg={6}>
                      <div className={`${pm.checkSelect_main}`}>
                        {/* <div className={`${pm.checkBox}`}>
                <label className={`d-block ${pm.checkbox}`}>
                  <input type="checkbox" checked={settingData.LimitNumberOfUses.active} onChange={(e: any) => setSettingData({ ...settingData, LimitNumberOfUses: { ...settingData.LimitNumberOfUses, active: e.target.checked, maxUser: !e.target.checked ? "" : settingData?.LimitNumberOfUses?.maxUser } })} />
                  <span className={`${pm.checkmark}`}></span>
                </label>
              </div> */}

                        <div className={`${pm.content}`}>
                          <div className={"d-flex gap-2"}>
                            <h4>Total Number of Uses:</h4>
                            <label className={`d-block ${pm.checkbox} m-0`}>
                              <input type="checkbox" {...register("maxUserCheck")} />
                              <span className={`${pm.checkmark}`}></span>
                            </label>
                          </div>
                          {/* <p>Select the total amount of times this can be used</p> */}

                          <Col xl={9} lg={11}>
                            <div className={`${pm.formBox} d-flex gap-3 ps-2 mt-2`}>
                              <label className="form-label pe-lg-3 pe-xl-4">Max uses per this <br /> promotion </label>
                              <input
                                //  defaultValue={editPromotionData?.LimitNumberOfUses?.maxUser}
                                type="number"
                                // ${pm.promotionForm} class removed
                                className={`w-auto text-center form-control`}
                                placeholder="Maximum Users "
                                // disabled={!settingData?.LimitNumberOfUses?.active}
                                value={settingData?.LimitNumberOfUses?.maxUser}
                                disabled={!watch("maxUserCheck")}
                                onChange={(e: any) => setSettingData({ ...settingData, LimitNumberOfUses: { active: e.target.value === "" ? false : true, maxUser: e.target.value } })}
                              />
                              
                            </div>
                          </Col>
                          {errorTrig && watch("maxUserCheck") && settingData?.LimitNumberOfUses?.maxUser === "" && <p className="errorMessage">This field is required</p>}
                        </div>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className={`${pm.checkSelect_main}`}>
                        {/* <div className={`${pm.checkBox}`}>
                <label className={`d-block ${pm.checkbox}`}>
                  <input type="checkbox" checked={settingData.LimitUsePerClient.active} onChange={(e: any) => setSettingData({ ...settingData, LimitUsePerClient: { ...settingData.LimitUsePerClient, active: e.target.checked, maxUse: !e.target.checked ? "" : settingData?.LimitUsePerClient?.maxUse } })} />
                  <span className={`${pm.checkmark}`}></span>
                </label>
              </div> */}
                        <div className={`${pm.content}`}>
                          <div className={"d-flex gap-2"}>
                            <h4> Maximum Uses Per Customer </h4>
                            <label className={`d-block ${pm.checkbox} m-0`}>
                              <input type="checkbox" {...register("maxClientCheck")} />
                              <span className={`${pm.checkmark}`}></span>
                            </label>
                          </div>

                          {/* <p>Each will be able to use once only</p> */}
                          <Col xl={9} lg={11}>
                            <div className={`${pm.formBox} d-flex gap-3 ps-2 mt-2`}>
                              <label className="form-label pe-lg-3 pe-xl-4">Limit Per customer for this promotion </label>
                              <input
                                //  defaultValue={editPromotionData?.LimitNumberOfUses?.maxUser}
                                type="number"
                                // ${pm.promotionForm} class removed
                                className={`w-auto text-center form-control`}
                                placeholder="Limit per Customer"
                                disabled={!watch("maxClientCheck")}

                                value={settingData?.LimitUsePerClient?.maxUse}
                                onChange={(e: any) => setSettingData({ ...settingData, LimitUsePerClient: { ...settingData?.LimitUsePerClient, maxUse: e.target.value } })}
                              />
                            </div>
                              {errorTrig && watch("maxClientCheck") && settingData?.LimitUsePerClient?.maxUse === "" && <p className="errorMessage">This field is required</p>}
                          </Col>
                        </div>
                      </div>
                    </Col>

                  </Row>

                  <Row>
                    <Col md={3}>
                      <div className={`${pm.formBox} mt-3`}>
                        <label htmlFor="gallery">Stickers</label>
                        <div className={`${pm.galleryBox}`}>
                          <div className={`${pm.item} ${pm.add}`}>
                            <label htmlFor="galleryImage">
                              <img
                                src={plusV2}
                                alt="add"
                                className={`${pm.add}`}
                              />
                              <input type="file" multiple value={""} id="galleryImage" onChange={handleImage} />
                            </label>
                          </div>
                          {imageProfile.map((image: any, i: any) => {
                            return (
                              <div className={`${pm.item} `} key={i}>
                                <img src={crossRed} alt="delete-icon" onClick={() => handleDeleteImage(image, i, "add")} className={`${pm.deleteCross}`} />
                                <img
                                  src={image.blob}
                                  className={pm.bgImage}
                                  alt="img"

                                />
                              </div>
                            )
                          })}

                          {existImage?.length !== 0 && existImage?.map((image: any, i: any) => {
                            return (
                              <div className={`${pm.item} `} key={i}>
                                <img src={crossRed} alt="delete-icon" onClick={() => handleDeleteImage(image, i, "update")} className={`${pm.deleteCross}`} />
                                <img
                                  src={image}
                                  className={pm.bgImage}
                                  alt="img"

                                />
                              </div>
                            )
                          })}
                        </div>

                      </div>
                    </Col>
                  </Row>





                  <Col md={12}>
                    <div className={`${pm.formBox}`}>
                      <div className={`${pm.btnsGroup}`}>
                        <button type="button" className={`btn ${pm.btn1}`}>Back</button>
                        <button type="button" className={`btn ${pm.btn2}`} onClick={handleSubmit(onSubmit)}>Save</button>

                      </div>
                    </div>

                  </Col>
                </Row>
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default AddCategory;
