import React, { useEffect, useState } from "react";
import cx from "./Role.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { httpRequest } from "../../../Apis/commonApis";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";

const AddRole = (props: any) => {
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [helpDocuments, setHelpDocuments] = useState<any[]>([]);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [count, setCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [featuredList, setFeaturedList] = useState<any>([]);
  const [isFeatured, setIsFeatured] = useState(false);

  console.log(isFeatured,"isFeatured")

  useEffect(() => {
    fetchHelpDocuments();
  }, [location?.state]);

  const fetchHelpDocuments = async () => {
    try {
      const res = await httpRequest('get_role', "GET", null, null);
      if (res.status === true) {
        setHelpDocuments(res.data);
      
        const roleId = location?.state?.roleId;
        if (roleId) {
          const roleToEdit = res.data.find((role: any) => role._id === roleId);
          if (roleToEdit) {
            setCurrentId(roleId);
            setValue("role", roleToEdit.role);
          }
        }
      } else {
        console.error("Failed to fetch roles", res);
      }
    } catch (error) {
      console.error("Error fetching roles", error);
    }
  };

  useEffect(() => {
    if (helpDocuments.length > 0 && currentId) {
      const currentRole = helpDocuments.find((doc) => doc._id === currentId);
      if (currentRole) {
        setValue("role", currentRole.role);
      }
    }
  }, [helpDocuments, currentId]);

  const onSubmit = async (data: any) => {
    setErrorMessage("");
    try {
     
      data.criticalRole = isFeatured;

      let res;
      if (currentId) {
        res = await httpRequest(`update_role/${currentId}`, "PATCH", data, "application/json");
      } else {
        res = await httpRequest('create_role', "POST", data, "application/json");
      }

      if (res.status === true) {
        setErrorMessage("");
        navigate('/settings/role');
        if (!currentId) {
          setCount((prevCount) => prevCount + 1);
        }
        fetchHelpDocuments();
        reset();
        setCurrentId(null);
      } else {
        setErrorMessage(res?.message || "Failed to create/update role");
        console.error("Failed to create/update role", res);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setErrorMessage("Failed to create/update role");
    }
  };

  // const onSubmit = async (data: any) => {
  //   setErrorMessage("");
  //   try {
  //     let res;
  //     if (currentId) {
  //       res = await httpRequest(`update_role/${currentId}`, "PATCH", data, "application/json");
  //     } else {
  //       res = await httpRequest('create_role', "POST", data, "application/json");
  //     }

  //     if (res.status === true) {
  //       setErrorMessage("");
  //       navigate('/settings/role');
  //       if (!currentId) {
  //         setCount((prevCount) => prevCount + 1);
  //       }
  //       fetchHelpDocuments();
  //       reset();
  //       setCurrentId(null);
  //     } else {
  //       setErrorMessage(res?.message || "Failed to create/update role");
  //       console.error("Failed to create/update role", res);
  //       setTimeout(() => {
  //         setErrorMessage("");
  //       }, 5000);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form", error);
  //     setErrorMessage("Failed to create/update role");
  //   }
  // };






  const handleCheckboxChange = (event: any, checkboxType: string) => {
    const checked = event.target.checked;
    if (checkboxType === "featured") {
      setIsFeatured(checked);
    } 
  };








  function handleFeatureList(e: any) {
    // if (e.target.checked) {
    //   let featureArr: any = []
    //   Array.from({ length: filterBusiness?.length > 12 ? 12 : filterBusiness?.length }, (_, i: any) => i)?.forEach((item: any, index: number) => {
    //     const currBusiness = filterBusiness?.[index]
    //     featureArr.push({ sq: index + 1, business: [], businessIdNumber: [] })
    //     // featureArr.push({ sq: index + 1, business: [currBusiness?._id], businessIdNumber: [currBusiness?.businessAccountNo] })
    //   })
    //   setFeaturedList(featureArr)
    // }
    handleCheckboxChange(e, "featured")
  }

  return (
    <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
      <div className={`${pm.pageTitle}`}>
        <div className={`${pm.titleInfo}`}>
         {currentId ? "Edit Role" : "Add Role"}
        </div>
        <div className={`${pm.rightInfo}`}></div>
      </div>

      <div className={`${pm.tabbingBox}`}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="tabNew1">
                <img src={basic} alt="info" className={`${pm.default}`} />
                Details
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="tabNew1">
              <Row>
                <Col xl={4} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Role</label>
                      <input
                        className="form-control"
                        placeholder="Role"
                        {...register("role", { required: true })}
                      />
                      {errors.role && <p className="errorMessage">Role is required</p>}
                      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} className="px-2">
                  <div>
                    <ul className={`${cx.additional_checkBox}`}>
                      <li>
                        <label>Critical Role</label>
                        <Checkbox checked={isFeatured} onChange={(e: any) => handleFeatureList(e)} />
                      </li>
                      
                    </ul>
                  </div>
                </Col>
                <Col xl={2} lg={4} md={4} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <button className="btn plusBtn" style={{ width: currentId ? "60%" : "25%" }} onClick={handleSubmit(onSubmit)}>
                      {currentId ? "Update" : "Add"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

export default AddRole;
