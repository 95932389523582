import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AddCategory from "../../../components/Modals/AddCategory";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AddTagType from "../../../components/Modals/AddTagType";

const StatusButton = (props: any) => {
  let { params } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status />
          </li>
        </ul>
      </div>

      <AddTagType show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const columns = [
  { field: "id", headerName: "Sr No", flex: 1, minWidth: 60 },

  {
    field: "tagType",
    headerName: "Tag Type",
    flex: 2,
    minWidth: 300,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 2,
    minWidth: 280,
    renderCell: (params: any) => <StatusButton params={params} />,
  },
];

const row = [
  {
    id: 1,
    tagType: "Hair Style Group",
    action: "",
  },
  {
    id: 2,
    tagType: "Hair Style Group",
    action: "",
  },
  {
    id: 3,
    tagType: "Hair Style Group",
    action: "",
  },
  {
    id: 4,
    tagType: "Hair Style Group",
    action: "",
  },
];

export default function TableDataGrid() {
  // const navigate = useNavigate();
  // const handleRowClick = (params:any) => {
  //   navigate(`/k-setup/branch-location-details`);
  // };

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        //onRowClick={handleRowClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
