import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
// import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";
import Tooltip from '@mui/material/Tooltip';
import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import BusinessAmenitiesM from "../../../components/Modals/BusinessAmenitiesModal";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, amenitieslist } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState("");
  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteAmenities = async () => {
    const res = await httpRequest(`delete_amenities/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
    } else {
      setError(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            {/* <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink> */}
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.status} api="amenities_status" id={params?.id}
              apiMethod="json" getLatest={amenitieslist} />
          </li>
        </ul>
      </div>
      <BusinessAmenitiesM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteAmenities={deleteAmenities} handleDeleteClose={handleDeleteClose} />
    </>
  );
};



export default function TableDataGrid(props: any) {
  const renderCellWithTooltip = (params: any) => (
    <Tooltip title={params?.value}>
      <div>{params?.value}</div>
    </Tooltip>
  );
  let row = props?.amenitiesdata?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      itemName: val?.AmenitiesGlobalList?.itemName,
      quantity: val?.qty,
      businessName: val?.Business?.businessName,
      branchName: val?.BranchLocation?.branchName,
      occupied: val?.occupied,
      available: val?.available,
      status: val?.status,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 180,
      renderCell: (params: any) => <StatusButton params={params?.row} amenitieslist={props?.amenitieslist} />,
    },
    { field: "itemName", headerName: "Item Name", flex: 2, minWidth: 110, renderCell: renderCellWithTooltip },
    { field: "branchName", headerName: "Branch Name", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "businessName", headerName: "Business Name", flex: 2, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "quantity", headerName: "Quantity", flex: 2, minWidth: 110, renderCell: renderCellWithTooltip },
    { field: "occupied", headerName: "Occupied", flex: 2, minWidth: 110, renderCell: renderCellWithTooltip },
    { field: "available", headerName: "Available", flex: 2, minWidth: 110, renderCell: renderCellWithTooltip },

    
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        //onRowClick={handleRowClick}
        autoHeight
        // hideFooterPagination={true}
        rowHeight={48}
        pageSize={100}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowId={(row) => row?.id}
      />
    </div>
  );
}
