import React, { useEffect, useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import bi from './BusinessInfo.module.scss';
import { NavLink } from "react-router-dom";

import Hair from "../../images/Hair.svg";
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { httpRequest } from "../../Apis/commonApis";
import { useForm, Controller } from "react-hook-form";
const BusinessInfoM = (props: any) => {
  let { show, handleClose,BusinessCategory } = props;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
 const[error,setError] = useState("");
 const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
 
 function handleCatSelect(id: string) {

  let newSelected: any = [...selectedCatIds];
  const selectedIndex = newSelected.indexOf(id);
  if (selectedIndex >= 0) {
    newSelected.splice(selectedIndex, 1);
  } else {
    newSelected.push(id);
  }
  setSelectedCatIds(newSelected);
  setError("");
}
  const AddnewBusiness = async (PostData:any) => {
    let data = {
      businessName:PostData.businessName,
      country:PostData.country,
      businessCatagoryId:selectedCatIds
    }
    const res:any = await httpRequest("add_business","Post", data, "withoutToken");
    
    if(res?.status == true){
      setError(res?.message)
      window.location.reload();
    }else{
      setError(res?.message)
    }
  };

  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Add Business</h5>
          </Col>
          <Row>
            <Col md={12} lg={12}>
              <Row>
                <Col lg={6}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>Business Name</Form.Label>
                    <input type="text" className="form-control" 
                     {...register("businessName", {
                      required: true,
                      pattern: /^[a-zA-Z ]+$/,
                      min: 1,
                    })}
                  />
                
                {errors?.businessName?.type === "required" && (
                  <p className={"errorMessage"}>
                    This field is required
                  </p>
                )}
                {errors?.businessName?.type === "pattern" && (
                  <p className={"errorMessage"}> Alphabets only</p>
                )}
                {errors?.businessName?.type === "min" && (
                  <p className={"errorMessage"}>
                    {" "}
                    minimum one character is necessary .
                  </p>
                )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>Country</Form.Label>
                    <input type="text" className="form-control"  {...register("country", {
                      required: true,
                      pattern: /^[a-zA-Z ]+$/,
                      min: 1,
                    })}
                  />
                
                {errors?.country?.type === "required" && (
                  <p className={"errorMessage"}>
                    This field is required
                  </p>
                )}
                {errors?.country?.type === "pattern" && (
                  <p className={"errorMessage"}> Alphabets only</p>
                )}
                {errors?.country?.type === "min" && (
                  <p className={"errorMessage"}>
                    {" "}
                    minimum one character is necessary .
                  </p>
                )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={12} className={`${m.title} mb-3 mt-4`}>
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Select Business Categories
                  </h2>
                </Col>
              </Row>
              <div className={`catContainer w-100`}>
                <Row>
                  {BusinessCategory?.map((item:any)=>{
                   return (<Col md={6}>
                    {selectedCatIds.includes(item._id) ? 
                      ( <div className="item active"  
                        onClick={() => handleCatSelect(item._id)}>
                        <img src={item?.Icon} alt="NA" />
                        <span className={`${bi.select}`}>{item?.BusinessCategoryName}</span>
                        </div>):(<div className="item"  
                        onClick={() => handleCatSelect(item._id)}>
                        <img src={item?.Icon} alt="NA" />
                        <span className={`${bi.select}`}>{item?.BusinessCategoryName}</span>
                        </div>)
                      }
                    </Col> )
                  })}
                  {/* <Col md={6}>
                    <div className="item active">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col> */}
                  {/* <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="item">
                      <img src={Hair} alt="NA" />
                      <span>Hair</span>
                    </div>
                  </Col> */}
                </Row>
              </div>

              <Row>
                <Col lg={12} className="mt-2">
                  <Button className={`${st.btnDisabled}`} onClick={handleSubmit(AddnewBusiness)}>Save</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessInfoM;
