import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import ClientM from "../../../components/Modals/ClientModal";
import { httpRequest } from "../../../Apis/commonApis";
import Tooltip from '@mui/material/Tooltip';
const StatusButton = (props: any) => {
  let { params, businessClient } = props;
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteClient = async () => {
    const res = await httpRequest(`delete_client/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
    } else {
      setError(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
            <Status status={params.row.status} api="client_status" id={params?.id}
              apiMethod="json" getLatest={businessClient} />
          </li> */}
        </ul>
      </div>
      <ClientM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteClient={deleteClient} handleDeleteClose={handleDeleteClose} />
    </>
  );
};
const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let { cientList, businessClient } = props
  let row = props?.cientList?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      clientName: `${val?.firstName} ${val?.lastName}`,
      adderss1: val?.adderss1,
      adderss2: val?.adderss2,
      businessName: val?.Business?.businessName,
      city: val?.city,
      createdBy: val?.createdBy,
      branch: val?.BranchLocation?.branchName,
      clientAccountNo: val?.clientAccountNo,
      country: val?.country,
      number: val?.mobile,
      email: val?.email,
      memberSince: val.createdAt?.split('T')[0],
      landmark: val?.landmark,
      state: val?.state,
      status: val?.status,
      postalCode: val?.postalCode,
      dateOfBirth: val?.dateOfBirth,
      gender: val?.gender,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params: any) => <StatusButton params={params} businessClient={businessClient} />,
    // },
    { field: "clientName", headerName: "Client Name", flex: 2, minWidth: 160, renderCell: renderCellWithTooltip },
    // { field: "clientAccountNo", headerName: "Client Account No", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "businessName", headerName: "Business Name", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "branch", headerName: "Branch Name", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "createdBy", headerName: "CreatedBy", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "number", headerName: "Number", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "email", headerName: "Email", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "dateOfBirth", headerName: "Date Of Birth", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "adderss1", headerName: "Address 1", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "adderss2", headerName: "Address 2", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "landmark", headerName: "Landmark", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "postalCode", headerName: "Postal Code", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "city", headerName: "City", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "state", headerName: "State", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "country", headerName: "Country", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "memberSince", headerName: "Member Since", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "lastVisit", headerName: "Last Visit", flex: 2, minWidth: 120, renderCell: renderCellWithTooltip },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        // hideFooterPagination={false}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
