import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./BranchAndLocation.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import TableDataGrid from "./DataGrid";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AmenitiesM from "../../../components/Modals/Amenities";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import CustomersMenu from "../../../components/Sidebar/CustomersMenu";
import CustomerM from "../../../components/Modals/CustomerModal";
import { httpRequest } from "../../../Apis/commonApis";

export default function CustomersList() {
  const [show, setShow] = useState(false);
  const[clientData,setclientData] = useState<any[]>([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDelete, setDeleteShow] = useState(false);
  const[selectUser,setSelectUser] = useState<any[]>([])
  const [error,setError] = useState("")
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  
  useEffect(() => {
    clientList();
  },[]);

  const clientList = async () => {
    const res = await httpRequest("get_client_listB2c","get", null, "withoutToken");
    if(res?.status == true){
      setclientData(res?.data);
    }else{
      setclientData([])
    }
  };
  const deleteselectedUsers = async () => {
    let data = {
      DeleteClientId:selectUser
    }
    const res = await httpRequest(`deleteClientB2C`, "DELETE", data, "withoutToken");
    if (res?.status == true) {
      handleDeleteClose()
      clientList()
    } else {
      setError(res?.message);
    }
  };
  const handleSelect =(selectUser:any)=>{
     setSelectUser(selectUser)
  }

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <CustomersMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Customer Setup</h5>
                  <p>Customers List</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    {/* <NavLink
                      to="#"
                      className={`btn ${tb.addBtn}`}
                      onClick={handleShow}
                    >
                      <AiOutlinePlusCircle className="me-1" />
                      Add New Customer
                    </NavLink> */}
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" />
                    </button>

                    <button className={`btn ${tb.upload}`} onClick={handleDeleteShow}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid clientData={clientData} clientList={clientList} handleSelect={handleSelect}/>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <DeletePopup show={showDelete} deleteselectedUsers ={deleteselectedUsers} handleDeleteClose={handleDeleteClose} />
      <CustomerM show={show} handleClose={handleClose} />
    </>
  );
}
