import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import BusinessUserM from "../../../components/Modals/BusinessUserPopup";
import BusinessInfoM from "../../../components/Modals/BusinessInfoModal";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";

const StatusButton = (props: any) => {
  let { params, getBusinessInfo } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token: any = localStorage.getItem('superAdmin_token');
  const [showDelete, setDeleteShow] = useState(false);
  const [error, seterror] = useState("")

  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deletebusinessInfo = async () => {
    const res = await httpRequest(`detete_business_info/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
      seterror(res?.message)
    } else {
      seterror(res?.message)
    }
  };

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <a
              className={`btn ${tb.edit}`}
              title="Edit"
              href={`https://business.justrentz.com/updateBusinessinfo/${params?.userId}/${params?.id}/${token}`}
              target="_blank"
            >
              <MdEdit />
            </a>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params.status} api="business_Info_status" id={params?.id}
              apiMethod="json" item={params?.businessUser || ''} getLatest={getBusinessInfo} defaultName="B2C" />
          </li>
        </ul>
      </div>

      <BusinessInfoM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deletebusinessInfo={deletebusinessInfo} handleDeleteClose={handleDeleteClose} />
    </>
  );
};
const PublishComp = (props: any) => {
  let { params, getBusinessInfo } = props;
  const pubStatus = params?.allData?.isPublished;
  const [message, setMessage] = useState('');
  const [show, setShow] = useState<boolean>(false);
  function handleClose() {
    setMessage('')
    setShow(false)
  }


  const confirmationResponse = async () => {
    const res = await httpRequest(`updateBusiness/${params?.id}`, "PATCH", { isPublished: 'published' }, "withoutToken");
    if (res?.status) {
      setMessage('business publish successfully')
      setShow(true)
      getBusinessInfo && getBusinessInfo()
    }
  }


  function handlePublish() {
    setMessage('do you want to publish this business')
    setShow(true)
  }


  return (
    <>
      {show && <SuccessPasswordModal type={message === 'do you want to publish this business' ? 'info' : 'success'} handleClose={handleClose} show={show} message={message} confirmationResponse={confirmationResponse} />}
      <div>
        <ul className={`${tb.actionTable}`}>
          <li className={`${tb.publish}`}>
            <span className={`${pubStatus ? pubStatus === 'inProgress' ? tb?.pending : pubStatus === 'UnPublish' ? tb.unpub : tb.pub : tb.unpub}`}>{pubStatus ? pubStatus === 'inProgress' ? 'Pending' : pubStatus : 'Unpublish'}</span>
            {(pubStatus === 'inProgress') && <button onClick={handlePublish}>{pubStatus === 'inProgress' ? "Click to Publish" : "Click to UnPublish"}</button>}
          </li>
        </ul>
      </div>
    </>
  );
};
const IconsCategory = (props: any) => {
  let { params } = props;
  return (
    <div>
      {params?.icon !== undefined ? (<img src={params?.icon} alt="icon" style={{ height: "30px", width: "30px" }} />) :
        (<img src="https://spa-saloon-images.s3.amazonaws.com/business/images/1698307376710-default%20%281%29.svg" alt="Category Icon" style={{ height: "30px", width: "30px" }} />)}
    </div>
  );
};
export default function TableDataGrid(props: any) {
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  let businessInfo: any = props?.data;

  let row = businessInfo?.map((val: any, index: any) => {
    console.log(val?.isActive, 'valActive');
    return {
      SrNo: index + 1,
      id: val?._id,
      icon: val?.profileImage,
      businessUser: `${val?.BusinessUser?.firstName} ${val?.BusinessUser?.lastName}`,
      userMobile: val?.BusinessUser?.mobile,
      userEmail: val?.BusinessUser?.email,
      businessName: val?.businessName,
      businessAccountNo: val?.businessAccountNo,
      businessURL: val?.businessURL,
      facebook: val?.facebook,
      instagram: val?.instagram,
      userId: val?.userId,
      website: val?.website,
      Country: val?.country,
      status: val?.status,
      about: val?.about,
      allData: val,
      businessCategory: val?.businessCatagoryId?.map((newval: any) => newval?.BusinessCategoryName)?.join(','),
      action: val?.isActive,
    };
  });
  const columns = [
    { field: "SrNo", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 210,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => <StatusButton params={params?.row} getBusinessInfo={props.getBusinessInfo} />
    },
    // {
    //   field: "publishStatus",
    //   headerName: "Publish Status",
    //   flex: 2,
    //   minWidth: 220,
    //   renderCell: (params: any) => <PublishComp params={params?.row} getBusinessInfo={props.getBusinessInfo} />
    // },
    {
      field: "icon",
      headerName: "Icon",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => <IconsCategory params={params?.row} />,
    },
    {
      field: "businessUser",
      headerName: "Business User",
      flex: 2,
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "userEmail",
      headerName: "User Email",
      flex: 2,
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "userMobile",
      headerName: "User Mobile",
      flex: 2,
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 2,
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "businessAccountNo", headerName: "Business Account No", flex: 1, minWidth: 160, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "businessCategory",
      headerName: "Business Category",
      flex: 2,
      minWidth: 160, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "businessURL", headerName: "Business URL", flex: 2, minWidth: 150, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "facebook", headerName: "Facebook URL", flex: 2, minWidth: 160, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "instagram", headerName: "Instagram URL", flex: 2, minWidth: 160, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "website", headerName: "Website URL", flex: 2, minWidth: 170, renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "Country",
      headerName: "Country",
      flex: 2,
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "about", headerName: "About Business", flex: 1, minWidth: 130,
      renderCell: (params: any) => {
        const aboutHtml: any = params?.row?.about || '';
        return <div dangerouslySetInnerHTML={{ __html: aboutHtml }} />;
      }
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        // hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        pageSize={100}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        getRowId={(row) => row?.id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}

      />
    </div>
  );
}
