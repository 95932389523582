export const myEventsList = [
  {
      
    end: new Date('2023-03-14T01:14:00.165Z'),
    start: new Date('2023-03-14T00:14:00.164Z'),
    title: 'test'
  },
  {
      
    end: new Date('2023-03-13T04:30:00.000Z'),
    start: new Date('2023-03-12T23:30:00.000Z'),
    title: 'test larger'
  },
  {
      
    end: new Date('2023-03-13T17:30:00.000Z'),
    start: new Date('2023-03-13T09:30:00.000Z'),
    title: 'test larger'
  },
  {
    
    end: new Date('2023-03-13T18:30:00.000Z'),
    start: new Date('2023-03-12T18:30:00.000Z'),
    title: 'test all day'
  },
  {
    
    end: new Date('2023-03-14T18:30:00.000Z'),
    start: new Date('2023-03-12T18:30:00.000Z'),
    title: 'test 2 days'
  },
  {
      
    end: new Date('2023-03-16T05:14:00.165Z'),
    start: new Date('2023-03-13T05:14:00.165Z'),
    title: 'test multi-day'
  }
]

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export function getMinDate(condition:any = null) {
  if (condition) {
    return disablePastDate(); 
  } else {
    return ""
  }
}