import React, { useEffect, useState, useRef } from "react";
import { groupMessagesByDate } from '../../constants/constant'
import cx from "./index.module.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { GrAttachment, GrClose } from "react-icons/gr";
import { db, storage } from "./firebase";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { IoDocumentTextOutline } from "react-icons/io5";
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import {
    arrayUnion,
    doc,
    Timestamp,
    updateDoc,
    onSnapshot,
    serverTimestamp,
} from "firebase/firestore";
import { BsArrowDownCircle } from "react-icons/bs";
import pdfImage from "../../images/pdf-img.png";
import FadeLoader from "react-spinners/FadeLoader";
const Chats = (props: any) => {
    const { selectedChat, } = props;
    const [text, setText] = useState<any>("");
    const scrollRef: any = useRef()
    const [img, setImg] = useState<any[]>([]);
    const [newImg, setNewImg] = useState<any[]>([]);
    const [imgType, setImgType] = useState<any>("");
    let [loading, setLoading] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState<any>([]);
    const newRef: any = useRef(null);
    const textRef: any = useRef(null);
    const textareaRef = useRef(null);
    const [imgUploadLoading, setImageUploadLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<any>(false);
    const [emoji, setShowEmoji] = useState(false);
    const [messages, setMessages] = useState<any>([]);
    const [inputKey, setInputKey] = useState(0);

    useEffect(() => {
        const textarea: any = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            const newHeight = Math.min(textarea.scrollHeight, 190);
            textarea.style.height = `${newHeight}px`;
        }
    }, [text]);

    useEffect(() => {
        if (selectedChat && messages) {
            scrollRef?.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [selectedChat, messages])

    useEffect(() => {
        if (selectedChat?._id) {
            const unsubscribe = onSnapshot(
                doc(db, "superadminuser", selectedChat?._id),
                {
                    includeMetadataChanges: true,
                    source: 'cache'
                },
                (documentSnapshot) => {
                    setMessages(documentSnapshot?.data()?.messages || [])
                }
            )

            return () => {
                unsubscribe();
            };
        }
    }, [selectedChat]);

    const handleSend = async () => {
        try {
            let sendTxt: any = text;
            if (newImg && newImg !== null) {
                let newMessageArr: any = [...messages];
                for (let item of newImg) {
                    newMessageArr?.push({
                        date: "",
                        fileSizeKB: 514.83984375,
                        id: 1709703953302,
                        imgName: item?.name,
                        imgformat: "img",
                        photoUrl: "",
                        type: "superadmin",
                        loading: true,
                    })
                    setMessages(newMessageArr)
                    const fileExtension = item?.name.split('.').pop();
                    const imgRef = ref(storage, `files/${v4()}.${fileExtension}`);
                    setImageUploadLoading(true);
                    await uploadBytes(imgRef, item).then(async (value) => {
                        const url = await getDownloadURL(value.ref);
                        const fileSizeBytes = value.metadata.size;
                        const fileSizeKB = fileSizeBytes / 1024;
                        if (url) {
                            setLoading(false)
                            const chatDocRef = doc(db, "superadminuser", selectedChat?._id);
                            await updateDoc(chatDocRef, {
                                messages: arrayUnion({
                                    id: Date.now(),
                                    photoUrl: url,
                                    type: "superadmin",
                                    imgName: item?.name,
                                    fileSizeKB: fileSizeKB,
                                    imgformat: imgType,
                                    date: Timestamp.now(),
                                }),
                                updateMessage: serverTimestamp(),
                                lastMsg: url,
                                type: imgType
                            });
                        }
                    });
                    setDisabled(false);
                    setImageUploadLoading(false);
                }
                setImg([])
                setNewImg([])
                setText("")

            } if (sendTxt) {
                if (text.trim() === "") {
                    return;
                }
                setText("");
                setSelectedEmoji([""])
                await updateDoc(doc(db, "superadminuser", selectedChat?._id), {
                    messages: arrayUnion({
                        id: Date.now(),
                        text: sendTxt,
                        type: "superadmin",
                        date: Timestamp.now(),
                    }),
                    updateMessage: serverTimestamp(),
                    lastMsg: sendTxt,
                    type: 'text'
                });
            }
            setShowEmoji(false)

            // let chatHead = chatHeadList?.filter((item: any) => item.id === chatId);
            let arrMsg: any[] = [];
            if (messages) {
                arrMsg = [...messages, sendTxt];
            }
            let counterMap: any = [];
            // for (let i = 0; i < allUserIds.length; i++) {
            //     if (allUserIds[i] === businessData._id) {
            //         counterMap.push({ id: allUserIds[i], counter: 0 });
            //     } else {
            //         let counterObj: any = chatHead?.[0].counterMap?.filter((counter: any) => counter?.id === allUserIds[i]);
            //         counterMap.push({
            //             id: allUserIds[i], counter: (counterObj && counterObj.length > 0)
            //                 ? counterObj[0].counter + 1 : 1
            //         });
            //     }

            // }
            if (selectedChat && selectedChat._id) {
                const chatUsersDocRef = doc(db, "superadminuser", selectedChat?._id);
                await updateDoc(chatUsersDocRef, {
                    id: selectedChat?._id,
                    lastMessage: sendTxt,
                    date: Timestamp.now(),
                    counterMap: counterMap,
                    allmessage: arrMsg,
                });
                const chatBusinessDocRef = doc(db, "superadminuser", selectedChat?._id);
                await updateDoc(chatBusinessDocRef, {
                    id: selectedChat?._id,
                    lastMessage: sendTxt,
                    date: Timestamp.now(),
                    counterMap: counterMap,
                    allmessage: arrMsg,
                });
            }
        } catch (err) {
            console.log(err)
        }
    };
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleSend();
            setText("")
        }
    };
    const handleFileChange = (e: any, type: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const urls = Array.from(files).map((file: any) => ({
                url: URL.createObjectURL(file),
                name: file.name
            }));
            setImg(urls);
            setNewImg(files);
            setImgType(type);

            // Reset the input key to re-render the input
            if (files.length <= 1) {
                setInputKey((prevKey: any) => prevKey + 1);
            }
        }
    };
    const handleRemoveImage = (url: any, e: any) => {
        let indexToRemove = img.indexOf(url);
        if (indexToRemove !== -1) {
            img?.splice(indexToRemove, 1);
            let imgArr: any = [];
            for (let i = 0; i < newImg?.length; i++) {
                if (i === indexToRemove) {
                    imgArr = imgArr
                } else {
                    imgArr.push(newImg[i])
                }
                setImg([...img])
                setNewImg([...imgArr])
            }
        } else {
            console.log("URL not found in the array.");
        }
    };
    const emojiPicker = () => {
        if (emoji == true) {
            setShowEmoji(false)
        } else {
            setShowEmoji(true)
        }
    };

    const handleEmojiSelect = (emoji: any) => {
        setSelectedEmoji((selectedEmoji: any) => [...selectedEmoji, emoji.emoji]);
    };
    useEffect(() => {
        if (selectedEmoji) {
            // Use useEffect to handle side effects after selectedEmoji is updated
            let emojiIc = selectedEmoji.join("");
            setText(emojiIc);
        }
    }, [selectedEmoji]);
    const groupedMessages = groupMessagesByDate(messages);
    return (
        <>
            <div className={`${cx.userchatBody}`} ref={newRef}>
                {groupedMessages?.map((chatItem: any, i: number) => {
                    return (
                        <div key={i} className={`${cx.dateHeading}`}>
                            <p>{chatItem?.date}</p>
                            {chatItem?.messages?.map((item: any, m: number) => {
                                const date = new Date(item?.date?.seconds * 1000);
                                let hours = date.getHours();
                                const amPM = hours >= 12 ? 'PM' : 'AM';
                                hours = hours % 12 || 12;
                                let minutes: any = date.getMinutes();
                                if (minutes < 10) {
                                    minutes = '0' + minutes;
                                }

                                // const amPM = hours >= 12 ? 'PM' : 'AM';
                                let fileNameWithoutExtension = item?.imgName?.substring(0, 5);
                                let fileExtension = item?.imgName?.substring(item?.imgName.lastIndexOf('.') + 1);
                                if (item?.type === "superadmin") {
                                    return (
                                        <div className={`${cx.chatMessage} ${cx.chatRight}`} key={m}>
                                            {item?.loading ? (
                                                <div className={`${cx.chatMessageBody} ${cx.loader}`}>
                                                    <span className={`${cx.loadingIndicator} spinner-border spinner-border-lg text-success`}
                                                        role="status"></span>
                                                </div>
                                            ) : item?.imgformat === "img" ? (
                                                <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                    {item?.photoUrl ? (
                                                        <img src={item?.photoUrl} className={cx.chatImage} alt="img-upload" ref={textRef} />
                                                    ) : (
                                                        <p ref={textRef}> {item?.text}</p>

                                                    )}
                                                    <time>{hours}:{minutes} {amPM}</time>

                                                </div>) : (
                                                <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                    {item?.photoUrl ? (
                                                        <div className={`${cx.pdf_main}`}>
                                                            <div className={`${cx.pdf_icon}`}>
                                                                <IoDocumentTextOutline />
                                                            </div>
                                                            <div className={`${cx.pdf_details}`}>
                                                                <div className={`${cx.line}`}>{fileNameWithoutExtension}...{fileExtension}</div>
                                                                <div className={`${cx.download_withsize}`}>
                                                                    <div className={`${cx.size}`}>{item?.fileSizeKB.toFixed(2)} KB </div>
                                                                    <div style={{ cursor: 'pointer' }} ref={textRef}>
                                                                        <a href={item?.photoUrl} download target='_blank' rel="noopener noreferrer">
                                                                            <BsArrowDownCircle />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p ref={textRef}> {item?.text}</p>

                                                    )}
                                                    <time>{hours}:{minutes} {amPM}</time>

                                                </div>
                                            )}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={`${cx.chatMessage} ${cx.chatLeft}`} key={m}>
                                            {item?.imgformat === "img" ? (
                                                <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                    {item?.photoUrl ? (
                                                        // <a href={item?.photoUrl} download target="_blank" rel="noopener noreferrer">
                                                        <img src={item?.photoUrl} className={cx.chatImage} alt="img-upload" ref={textRef} />
                                                        // </a>
                                                    ) : (
                                                        <p ref={textRef}> {item?.text}</p>
                                                    )}
                                                    <time>{hours}:{minutes} {amPM}</time>
                                                </div>) : (
                                                <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                    {item?.photoUrl ? (
                                                        <div className={`${cx.pdf_main}`}>
                                                            <div className={`${cx.pdf_icon}`}>
                                                                <IoDocumentTextOutline />
                                                            </div>
                                                            <div className={`${cx.pdf_details}`}>
                                                                <div className={`${cx.line}`}>{fileNameWithoutExtension}...{fileExtension}</div>
                                                                <div className={`${cx.download_withsize}`}>
                                                                    <div className={`${cx.size}`}>{item?.fileSizeKB.toFixed(2)} KB </div>
                                                                    <div style={{ cursor: 'pointer' }} ref={textRef}>
                                                                        <a href={item?.photoUrl} download target='_blank' rel="noopener noreferrer">
                                                                            <BsArrowDownCircle />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p ref={textRef}> {item?.text}</p>
                                                    )}
                                                    <time>{hours}:{minutes} {amPM}</time>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )
                })}
                <div role="img" ref={scrollRef} style={{ height: '10px', width: '100%' }}>

                </div>
                <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />
            </div>

            {<div className={`${cx.chatSubmit}`}>
                {(
                    <div className={`${cx.chatSubmiBody}`}>
                        <div className={`${cx.actionBtns}`}>
                            {/* <button title="Voice">
                            <MdKeyboardVoice style={{ fontSize: "22px" }} />
                        </button> */}
                            <button title="Upload Files">
                                <GrAttachment />
                                <input type="file" key={inputKey} onChange={(e: any) => handleFileChange(e, "img")} accept="image/*" multiple />
                            </button>
                            <button title="Upload Files">
                                <IoDocumentTextOutline />
                                <input type="file" key={inputKey} accept=".txt, .pdf, .doc, .docx, .pptx, .xlsx" onChange={(e: any) => handleFileChange(e, "doc")} multiple />
                            </button>
                        </div>

                        <div className={`${cx.typeForm}`}>
                            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                                {img?.map((item: any) => {
                                    const parts = item?.name.split(".");
                                    const extension = parts[parts.length - 1];
                                    return (
                                        <>
                                            <div className={`${cx.sendImageBox} ${imgUploadLoading ? cx.imageUploadLoadingBox : ""}`}>
                                                {/* {imgUploadLoading && <span className={`${cx.loader} spinner-border spinner-border-lg text-success`}
                                                    role="status"></span>} */}
                                                <img src={imgType === "img" ? item?.url : pdfImage} alt="Selected" className={cx.sendImage} />
                                                <button className={`${cx.crossButton} btn btn-outline-secondary`} type="button" onClick={(e: any) => handleRemoveImage(item, e)}>
                                                    <GrClose />
                                                </button>
                                                <div className={`${cx.type_nameFile}`}><div className={`${cx.nameFiles}`}>{parts[0]}</div><span>.{extension}</span></div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>

                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Write a message..."
                                value={text}
                                onChange={(e) => {
                                    setText(e.target.value);
                                    setSelectedEmoji([e.target.value])
                                }}
                                onKeyDown={handleKeyPress}

                            /> */}

                            <div className={cx.chatTextareaWrapper}>
                                <textarea
                                    rows={1}
                                    ref={textareaRef}
                                    className={`${cx.chatTextarea} form-control`}
                                    placeholder="Write a message..."
                                    value={text}
                                    onChange={(e) => {
                                        setText(e.target.value);
                                    }}
                                    onKeyDown={handleKeyPress}
                                ></textarea>
                            </div>
                        </div>

                        <div className={`${cx.submitAction}`}>
                            {/* <button title="Send" disabled={disabled}    className={`${text ? cx.activeTyping : ""} ` }>
                                <FaTelegramPlane onClick={handleSend} />
                            </button> */}
                            <button
                                title="Send"
                                disabled={disabled}
                                className={`${text !== "" ? cx.activeTyping : ""}`}

                            >
                                <FaTelegramPlane onClick={handleSend} />
                            </button>


                            <button title="Send" onClick={emojiPicker}>
                                <MdOutlineEmojiEmotions />
                            </button>
                        </div>

                        {emoji == true ? (
                            <div>
                                <EmojiPicker style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '380px', transform: 'translate(0%, -100%)' }} emojiStyle={EmojiStyle.APPLE} previewConfig={{ showPreview: false }} onEmojiClick={handleEmojiSelect} autoFocusSearch={true} />
                            </div>) : ""}
                    </div>)}


            </div>}
        </>


    );
};

export default Chats;
