// import React from "react";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Error404 from "./pages/Error404/Error404";
import Navigation from "./pages/Navigation/Navigation";
import BusinessCategory from "./pages/Settings/BusinessCategory/BusinessCategory";
import ServiceCategory from "./pages/Settings/ServiceCategory/ServiceCategory";
import Amenities from "./pages/Settings/Amenities/Amenities";
import Keywords from "./pages/Settings/Keywords/Keywords";
import KeywordGroup from "./pages/Settings/KeywordGroup/KeywordGroup";
import Tag from "./pages/Settings/Tag/Tag";
import TagType from "./pages/Settings/TagType/TagType";
import Classification from "./pages/Settings/Classification/Classification";
import Channel from "./pages/Settings/Channel/Channel";
import BusinessAmenities from "./pages/BusinessSetUp/Amenities/BusinessAmenities";
import BusinessTeam from "./pages/BusinessSetUp/BusinessTeam/BusinessTeam";
import BusinessServiceCategory from "./pages/BusinessSetUp/ServiceCategory/ServiceCategory";
import BusinessServices from "./pages/BusinessSetUp/Services/BusinessServices";
import BusinessClient from "./pages/BusinessSetUp/BusinessClient/BusinessClient";
import BusinessUser from "./pages/BusinessSetUp/BusinessUser/BusinessUser";
import Branchmembership from "./pages/BusinessSetUp/Membership/membership";
import BusinessInfo from "./pages/BusinessSetUp/BusinessInfo/BusinessInfo";
import CustomersList from "./pages/CustomerSetUp/CustomerList/CustomersList";
import ImagesManagement from "./pages/WebpageSetup/ImagesManagement/ImagesManagement";
import InformationManagement from "./pages/WebpageSetup/InformationManagement/InformationManagement";
import SectionPage from "./pages/WebpageSetup/SectionPage/SectionPage";
import Login from "./pages/Login/Login";
import BusinessBranch from "./pages/BusinessSetUp/Branch/BusinessBranch";
import BusinessTeamTitle from "./pages/BusinessSetUp/BusinessTeam_title/BusinessTeam_title";
import Package from "./pages/BusinessSetUp/Package/Package";
import Sale from "./pages/BusinessSetUp/Sale/Sales";

import BookingData from "./pages/BusinessSetUp/Booking/Booking";
import Promotion from "./pages/BusinessSetUp/Promotion/Promotion";
import AddPromotion from "./pages/BusinessSetUp/Promotion/AddPromotion";
import HelpDocument from "./pages/BusinessSetUp/HelpDocument/HelpDocument";
import AddHelpDocument from "./pages/BusinessSetUp/HelpDocument/AddHelpDocument";
import EditHelpDocument from "./pages/BusinessSetUp/HelpDocument/EditHelpDocument";
import DataShow from "./pages/BusinessSetUp/DataShow/DataShow";
import AddDataShow from "./pages/BusinessSetUp/DataShow/AddDataShow";
import AddRole from "./pages/BusinessSetUp/Role/AddRole";
import RoleShow from "./pages/BusinessSetUp/Role/RoleShow";
import BussinessManagements from "./pages/WebpageSetup/BusinessManagements/BussinessManagements";
import BusinessSection from "./pages/WebpageSetup/BusinessSection/BusinessSection";
import AddBusinessPage from "./pages/WebpageSetup/BusinessSection/AddBusinessPage";
import Collections from "./pages/WebpageSetup/Collectioons/Collections";
import AddCollection from "./pages/WebpageSetup/Collectioons/AddCollection";
import Attribute1 from "./pages/Settings/Attribute1/Attribute1";
import AtrributeItem2 from "./pages/Settings/AttributeItem2/Attribute2"
import AttributeValue3 from "./pages/Settings/AttributeValue3/Attribute3"
import AttributePopup1 from "./components/Modals/AttributePopup1";
import AddServiceCategory from "./pages/Settings/ServiceCategory/AddServiceCategory";
import SuggestedServices from "./pages/Settings/SuggestedServices/SuggestedServices";
import AddSuggestedService from "./pages/Settings/SuggestedServices/AddSuggestedService";
import RewardPage from "./pages/WebpageSetup/RewardsSetting/RewardPage";
import AddRewardSection from "./pages/WebpageSetup/RewardsSetting/AddRewardSection";
import { Chat } from "@mui/icons-material";
import ChatBox from "./pages/Chat/ChatBox";
import Headline from "./pages/Settings/Headline/Headline";
import AddHeadline from "./pages/Settings/Headline/AddHeadline";
import TipOfTheDay from "./pages/Settings/TipOfTheDay/TipOfTheDay";
import AddTip from "./pages/Settings/TipOfTheDay/AddTip";

// import AddPromotion from "./pages/BusinessSetUp/Promotion/AddPromotion";


const App = () => {
  const navigate = useNavigate();
  let token = localStorage.getItem("superAdmin_token");
  useEffect(() => {
    if (!token) {
      navigate("/", { replace: false });
    }
  }, [])
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="chat" element={<Navigation />}>
        <Route index element={<Navigate to="business" />} />
        <Route path="business" element={<ChatBox />} />
      </Route>
      <Route path="settings" element={<Navigation />}>
        <Route index element={<Navigate to="business-category" />} />
        <Route path="business-category" element={<BusinessCategory />} />

        <Route path="tip" element={<TipOfTheDay />} />
        <Route path="tip/add-tip" element={<AddTip />} />
        <Route path="tip/edit-tip" element={<AddTip />} />

        <Route path="headline" element={<Headline />} />
        <Route path="headline/add-headline" element={<AddHeadline />} />
        <Route path="headline/edit-headline" element={<AddHeadline />} />

        <Route path="business-category" element={<BusinessCategory />} />

        <Route path="service-category" element={<ServiceCategory />} />
        <Route path="amenities" element={<Amenities />} />
        <Route path="keywords" element={<Keywords />} />
        <Route path="keywords-group" element={<KeywordGroup />} />
        <Route path="tag" element={<Tag />} />

        <Route path="service-category" element={<ServiceCategory />} />
        <Route path="service-category/add-service-category" element={<AddServiceCategory />} />
        <Route path="service-category/edit-service-category" element={<AddServiceCategory />} />

        <Route path="services" element={<SuggestedServices />} />
        <Route path="services/add-suggested-services" element={<AddSuggestedService />} />
        <Route path="services/edit-suggested-services" element={<AddSuggestedService />} />

        <Route path="promotion" element={<Promotion />} />

        <Route path="add-promotion" element={<AddPromotion />} />
        <Route path="helpdocument" element={<HelpDocument />} />
        <Route path="attribute1" element={<Attribute1 />} />
        {/* <Route path ="edit-attribute" element={<AttributePopup1 />}/> */}
        <Route path="attribute2" element={<AtrributeItem2 />} />
        <Route path="attribute3" element={<AttributeValue3 />} />
        {/* this DataShow name is Category Web Pages  */}
        <Route path="datashow" element={<DataShow />} />
        <Route path="edit-webpage" element={<AddDataShow />} />
        {/* this DataShow name is Category Web Pages  */}
        <Route path="add-datashow" element={<AddDataShow />} />
        <Route path="role" element={<RoleShow />} />
        <Route path="add-role" element={<AddRole />} />
        <Route path="edit-role" element={<AddRole />} />



        <Route path="add-helpdocument" element={<AddHelpDocument />} />
        <Route path="edit-helpdocument" element={<EditHelpDocument />} />

        <Route path="tag-type" element={<TagType />} />
        <Route path="classifiication" element={<Classification />} />
        <Route path="channel" element={<Channel />} />
      </Route>

      <Route path="business" element={<Navigation />}>
        <Route index element={<Navigate to="business-user" />} />
        <Route path="amenities" element={<BusinessAmenities />} />
        <Route path="teams" element={<BusinessTeam />} />
        <Route path="teams-title" element={<BusinessTeamTitle />} />
        <Route path="service-category" element={<BusinessServiceCategory />} />
        <Route path="services" element={<BusinessServices />} />
        <Route path="client" element={<BusinessClient />} />
        <Route path="business-user" element={<BusinessUser />} />
        <Route path="membership" element={<Branchmembership />} />
        <Route path="branch" element={<BusinessBranch />} />
        <Route path="package" element={<Package />} />
        <Route path="Sales" element={<Sale />} />
        {/* <Route path ="Promotion" element={<Promotion/>}/> */}
        <Route path="Booking" element={<BookingData />} />
        <Route path="business-info" element={<BusinessInfo />} />
      </Route>
      <Route path="customer-setup" element={<Navigation />}>
        <Route index element={<Navigate to="customers" />} />
        <Route path="customers" element={<CustomersList />} />
      </Route>
      <Route path="webpages" element={<Navigation />}>
        <Route index element={<Navigate to="business-management" />} />
        <Route path="section-page" element={<SectionPage />} />
        <Route path="images-management" element={<ImagesManagement />} />
        <Route path="business-management" element={<BussinessManagements />} />
        <Route path="business-section" element={<BusinessSection />} />
        <Route path="business-section/add-business-section" element={<AddBusinessPage />} />
        <Route path="business-section/edit-business-section" element={<AddBusinessPage />} />

        <Route path="collections" element={<Collections />} />
        <Route path="collections/add-collection" element={<AddCollection />} />
        <Route path="collections/edit-collection" element={<AddCollection />} />
        <Route
          path="information-management"
          element={<InformationManagement />}
        />
        <Route path="reward-setting" element={<RewardPage />} />
        <Route path="reward-setting/add-reward" element={<AddRewardSection />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
