import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import TableDataGrid from "./DataGrid";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";

export default function Sale() {
  const [show, setShow] = useState(false);
  const [salesList,setSalesList] = useState<any[]>([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    getSales()
  },[])
 
  const getSales = async () => {
    const res = await httpRequest("get_sales","get", null, "withoutToken");
    if(res.status){
      setSalesList(res?.data)
    }
  };

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <BusinessMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Sales</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>
        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid salesList={salesList}/>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <LocationM show={show} handleClose={handleClose} />
    </>
  );
}
