import React, { useCallback, useState, useEffect } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import TableDataGrid from "./DataGrid";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import AddBranch from "../../../components/Modals/AddBranch";
import { httpRequest } from "../../../Apis/commonApis";
import FadeLoader from "react-spinners/FadeLoader";
export default function BusinessBranch() {
  const [show, setShow] = useState(false);
  const [branchData, setbranchData] = useState<any[]>([]);
  let [loading, setLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    branchlist();
  }, []);
  const branchlist = async () => {
    const res = await httpRequest("branch_list", "get", null, "withoutToken");
    if (res?.status == true) {
      setLoading(false)
      setbranchData(res?.data)
    } else {
      setLoading(false)
      setbranchData([])
    }
  };
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <BusinessMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Branch</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" />
                    </button>
                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`} >
                {loading === true ? (
                  <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />
                ) : (<TableDataGrid branchData={branchData} branchlist={branchlist} />)}

              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <AddBranch show={show} handleClose={handleClose} />
    </>
  );
}
