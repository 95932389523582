import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { Controller, useForm } from "react-hook-form";
import { Tab, Nav, Row, Col, Accordion } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";
import uploadFile from "../../../images/iconUpload.svg";
import uploadIcon from "../../../images/uploadIcon.svg";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";
import OnOffSwitch from "../../../components/Forms/OnOffSwitch";
import { IoIosCloseCircle } from "react-icons/io";
import crossIcon from '../../../assest/crossRed.svg'
import { httpRequest } from "../../../Apis/commonApis";
import { useLocation, useNavigate } from "react-router-dom";

const AddSuggestedService = (props: any) => {
    const [editServiceData, setEditServiceData] = useState<any>(null);
    const [serviceCategoryList, setServiceCategoryList] = useState<any>([]);

    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("")
    const handleClose = () => setShow(false);

    const navigate = useNavigate();
    const location = useLocation();

    const [apiError, setApiError] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        control
    } = useForm();

    useEffect(() => {
        if (editServiceData) {
            reset();
            let srvCatId = editServiceData.SuperServiceCategory?._id ? editServiceData.SuperServiceCategory?._id : editServiceData.SuperServiceCategory;
            setValue('serviceCatagoryId', srvCatId || "");
            setValue('serviceName', editServiceData?.serviceName);
        }
    }, [editServiceData]);

    const getService = async () => {
        const res = await httpRequest(`getBusinessService?_id=${location?.state?._id}&type=list`, "get", null, "json");
        if (res.status === true) {
            setEditServiceData(res?.data?.[0] || null);
        }
      };

    useEffect(() =>{
        if(location.state?._id){
            getService()
        }
    },[location.state?._id])

    useEffect(() => {
        getServiceCatagory();

    }, []);

    const getServiceCatagory = async () => {
        const res = await httpRequest(`getServiceCategory`, "get", null, "json");
        if (res.status === true) {
            setServiceCategoryList(res?.data);
        }
    };

    const submitHandler = async (postdata: any) => {
        const basicData = { ...postdata };

        setLoading(true);
        let apiUrl = 'createBusinessService';
        let apiMethod = 'post';
        if (editServiceData && location.state?._id) {
            apiUrl = `updateBusinessService/${location.state._id}`;
            apiMethod = 'PATCH';
        }
        const res = await httpRequest(apiUrl, apiMethod, basicData, "json");
        if (res.status) {
            setApiError("");
            setShow(true);
            setMessage(res.message)
            setTimeout(() => {
                navigate("/settings/services")
            }, 2000)
        } else {
            setApiError(res.message)
        }
        setLoading(false);
    };

    return (
        <>
            <SuccessPasswordModal handleClose={handleClose} show={show} message={message} />

            <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
                <div className={`${pm.pageTitle}`}>
                    <div className={`${pm.titleInfo}`}>
                        {editServiceData ? editServiceData?.serviceName : "Add Suggested Service"}
                    </div>
                    <div className={`${pm.rightInfo}`}></div>
                </div>

                <div className={`${pm.tabbingBox}`}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="tabNew1">
                                    <img src={basic} alt="info" className={`${pm.default}`} />
                                    Details
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="tabNew1">
                                <form onSubmit={handleSubmit(submitHandler)}>
                                    <Row>

                                        <Col lg={6}>
                                            <div className={`${pm.formBox}`}>
                                                <label className="form-label">Service name </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Service name"
                                                    defaultValue={editServiceData?.serviceName}
                                                    {...register("serviceName", {
                                                        required: true,
                                                        pattern: {
                                                            value: /\S/,
                                                            message: "This field is required",
                                                        },
                                                    })}
                                                />
                                                {errors?.serviceName?.type === "required" && (
                                                    <p className={"errorMessage"}>This field is required</p>
                                                )}
                                                {errors?.serviceName?.type === "pattern" && (
                                                    <p className={"errorMessage"}>This field is required</p>
                                                )}

                                                {apiError !== "" && (
                                                    <p className={"errorMessage"}>{apiError}</p>
                                                )}
                                            </div>
                                        </Col>


                                        <Col md={6}>
                                            <div className={`${pm.formBox}`}>
                                                <label className="form-label">Service category</label>
                                                <Controller
                                                    name="serviceCatagoryId"
                                                    control={control}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-select"
                                                        >
                                                            <option value="">--Select--</option>
                                                            {serviceCategoryList?.map((sc: any) => (
                                                                <option value={sc._id} key={sc._id}>
                                                                    {sc.serviceCategoryName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors.serviceCatagoryId && (
                                                    <p className={"errorMessage"}>This field is required</p>
                                                )}
                                            </div>
                                        </Col>


                                        <Col md={12}>
                                            <div className={`${pm.formBox}`}>
                                                <div className={`${pm.btnsGroup}`}>
                                                    <button
                                                        className={`btn ${pm.btn1}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate(-1);
                                                        }}
                                                    >
                                                        Back
                                                    </button>
                                                    <button className={`btn ${pm.btn4}`}  disabled={loading}>
                                                        {loading ? (
                                                            <div
                                                                className="spinner-border spinner-border-sm text-light"
                                                                role="status"
                                                            ></div>
                                                        ) : (
                                                            editServiceData ? "Update" : 'Save'
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
        </>
    );
};

export default AddSuggestedService;
