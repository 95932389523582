import React, { useState } from "react";
import Select from "react-select";
import st from "../../../assets/stylesheet/style.module.scss";

interface Option {
  value: string;
  label: string;
}
interface MultiSelect {
  onChange?: void | any;
  value?: Option[] | any;
  options?: Option[] | any;
  defaultVal?: {};
  list?: any;
  disabled?: any;
  style?: any
}

const MultiSelect: React.FC<MultiSelect> = ({
  onChange,
  value,
  list,
  defaultVal,
  disabled = false,
  style
}) => {
  const options = list?.[0]?.label ? list : list?.map((tag: any) => {
    return { value: tag, label: tag };
  });

  return (
    <>
      {/* <div className={`${st.formBox}`}> */}
      <Select
        options={options}
        closeMenuOnSelect={false}
        defaultValue={defaultVal ? defaultVal : null}
        value={value}
        isMulti
        onChange={onChange}
        placeholder="---Select---"
        isDisabled={disabled}
        styles={style ? style : {}}
      />
      {/* <div>
        {selectedOptions.map((option) => (
          <span key={option.value}>{option.label}, </span>
        ))}
      </div> */}
      {/* </div> */}
    </>
  );
};

export default MultiSelect;
