import React, { useCallback, useState,useEffect } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import FadeLoader from "react-spinners/FadeLoader";
import TableDataGrid from "./DataGrid";
import AmenitiesM from "../../../components/Modals/Amenities";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import { httpRequest } from "../../../Apis/commonApis";

export default function BusinessTeam() {
  const [show, setShow] = useState(false);
  const [listTeam, setListTeam] = useState([]);
  let [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getteamList();
  },[]);
  const getteamList = async () => {
    const res = await httpRequest("get_team","get", null, "withoutToken");
    if(res?.status == true){
      setLoading(false)
      setListTeam(res?.data)
    }else{
      setLoading(false)
      setListTeam([])
    }
  };
  return (

    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <BusinessMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Team</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className={`${tb.dataTable}`}>
              {loading === true ? (
              <FadeLoader loading = {loading} margin = {2} width = {5} color="#36d7b7" style={{position:"absolute", top:"50%" , left:"50%"}}/>
              ):( <TableDataGrid listTeam={listTeam} getteamList={getteamList}/>)}
               
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>

      <AmenitiesM show={show} handleClose={handleClose} />
    </>
  );
}
