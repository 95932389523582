import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

import Tooltip from '@mui/material/Tooltip';
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import { httpRequest } from "../../../Apis/commonApis";
import ResetPasswordModal from "../../../components/Modals/ResetPasswordModal";
import Verify from "../../../components/Forms/Verify";

const StatusButton = (props: any) => {
  let { params, clientList } = props;
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const deleteBusinessClient = async () => {
    const res = await httpRequest(`deleteClientB2C?id=${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      clientList()
    } else {
      setError(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.status} api="update_status_clients" id={params?.id} getLatest={clientList}
              apiMethod="json" item={params?.customerName || ''}/>
          </li>
        </ul>
      </div>
      <ResetPasswordModal show={show} api="changePasswordB2c" handleClose={handleClose} userData={params?.id} />
      <DeletePopup show={showDelete} userId={deleteBusinessClient} handleDeleteClose={handleDeleteClose} />
    </>
  );
};
const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let { clientData, clientList, handleSelect } = props
  const [selectedRows, setSelectedRows] = useState < any > ("")
  let row = clientData?.map((item: any, index: any) => {
    let createDate: any = item.createdAt ? new Date(item.createdAt) : null;
    let updateDate: any = item.updatedAt ? new Date(item.updatedAt) : null;
    // Convert to "7 March 2024" format
    createDate = createDate ? `${createDate.getDate()} ${createDate.toLocaleString('default', { month: 'long' })} ${createDate.getFullYear()}` : "N/A";
    updateDate = updateDate ? `${updateDate.getDate()} ${updateDate.toLocaleString('default', { month: 'long' })} ${updateDate.getFullYear()}` : "N/A";

    return {
      Sr_No: index + 1,
      id: item?._id,
      country: item?.country,
      customerName: `${item?.firstName} ${item?.lastName}`,
      email: item?.email,
      businessName: item?.Business?.map((item: any) => item?.Business?.businessName).join(","),
      phoneNumber: item?.mobile,
      adderss1: item?.adderss1,
      adderss2: item?.adderss2,
      landmark: item?.landmark,
      postalCode: item?.postalCode,
      state: item?.state,
      clientId: item?.clientId,
      city: item?.city,
      status: item?.status,
      updatedata: updateDate,
      creationdate: createDate,
      emailVerify: item?.emailVerify,
      mobileVerify: item?.mobileVerify,
      lastlocation: item?.lastLocation,
      lastdeviceused: item?.device,
      // customerType: "Regular",
      action: item?.isActive,
    }
  })

  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => <StatusButton params={params?.row} clientList={clientList} />,
    },
    { field: "clientId", headerName: "Client Id", flex: 1, minWidth: 110 },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "emailVerify",
      headerName: "Email Verify",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => <Verify params={params?.row} api="update_customer_profile" emailVerify="emailVerify" getLatest={clientList} />,
    },
    { field: "email", headerName: "Email", flex: 1, minWidth: 110, renderCell: renderCellWithTooltip },
    {
      field: "mobileVerify",
      headerName: "Mobile Verify",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => <Verify params={params?.row} api="update_customer_profile" mobileVerify="mobileVerify" getLatest={clientList} />,
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1, minWidth: 130, renderCell: renderCellWithTooltip },
    { field: "creationdate", headerName: "Creation Date", flex: 1, minWidth: 130, renderCell: renderCellWithTooltip },
    { field: "updatedata", headerName: "Last Update Data", flex: 1, minWidth: 130 },
    { field: "lastdeviceused", headerName: "Last Device Used", flex: 1, minWidth: 130 },
    { field: "lastlocation", headerName: "Last location", flex: 1, minWidth: 130 },
    { field: "businessName", headerName: "Business Name", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "country", headerName: "Country Name", flex: 1, minWidth: 130 },
    { field: "adderss1", headerName: "Address 1", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "adderss2", headerName: "Address 2", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "landmark", headerName: "Landmark", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "postalCode", headerName: "Postal Code", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "city", headerName: "City", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "state", headerName: "State", flex: 2, minWidth: 140, renderCell: renderCellWithTooltip },
    {
      field: "customerType",
      headerName: "Customer Type",
      flex: 1,
      minWidth: 130,
    },
  ];

  const handleSelectionChange = (selectionModel: any) => {
    setSelectedRows(selectionModel);
  };
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        // hideFooterPagination={false}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
}
