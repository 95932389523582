import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import { httpRequest } from "../../../Apis/commonApis";
import Tooltip from '@mui/material/Tooltip';
const StatusButton = (props: any) => {
  let { params, getteamList } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, seterror] = useState("");
  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteTeamMember = async () => {
    const res = await httpRequest(
      `delete_team_member/${params?.id}`,
      "DELETE",
      null,
      "withoutToken"
    );
    if (res?.status == true) {
      window.location.reload();
    } else {
      seterror(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
            <Status status={params.status} api="team_status" id={params?.id}
              apiMethod="json" getLatest={getteamList} />
          </li> */}
        </ul>
      </div>
      <AmenitiesM show={show} handleClose={handleClose} />
      <DeletePopup
        show={showDelete}
        deleteTeamMember={deleteTeamMember}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};
const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);
export default function TableDataGrid(props: any) {
  let row: any = props?.listTeam?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      branch: val?.branchId?.map((val: any) => { return val?.branchName }),
      employeeID: val?.teamMemberIdNo,
      name: `${val?.firstName} ${val?.lastName}`,
      // TeamTitle: val?.Role[0]?.role?.map((item: any) => item?.teamTitle),
      TeamTitle: val?.Role?.[0]?.role?.map((item: any) => item?.teamTitle).join(', ') || '',
      email: val?.email,
      mobile: val?.mobile,
      status: val?.status,
      businessName: val?.Business?.businessName,
      joiningDate: val.createdAt?.split('T')[0],
      service: `${val?.serviceId?.map((item: any) => { return item?.serviceName })}`,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 230,
    //   renderCell: (params: any) => <StatusButton params={params?.row} getteamList={props.getteamList} />,
    // },
    { field: "name", headerName: "Name", flex: 1, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "TeamTitle", headerName: "Team Title", flex: 1, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "employeeID", headerName: "Employee ID", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },
    { field: "service", headerName: "Service", flex: 1, minWidth: 250, renderCell: renderCellWithTooltip },
    { field: "branch", headerName: "Branch", flex: 1, minWidth: 200, renderCell: renderCellWithTooltip },
    { field: "businessName", headerName: "Business Name", flex: 1, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "email", headerName: "Email", flex: 1, minWidth: 220, renderCell: renderCellWithTooltip },
    { field: "mobile", headerName: "Mobile", flex: 1, minWidth: 120, renderCell: renderCellWithTooltip },
    { field: "joiningDate", headerName: "Joining Date", flex: 1, minWidth: 150, renderCell: renderCellWithTooltip },

  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        //onRowClick={handleRowClick}
        autoHeight
        // hideFooterPagination={true}
        rowHeight={48}
        pageSize={100}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
