import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import TableDataGrid from "./DataGrid";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";
import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import { useNavigate } from "react-router-dom";

export default function Promotion() {
  const [show, setShow] = useState(false);
  const [promotion,setPromotion] = useState<any[]>([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate()


  const getData = async () => {
    const res = await httpRequest("getPromotion", "get", null, null);
 
    console.log(res,"res")
     if (res?.status == true) {
      setPromotion(res?.data)
     } else {
     }
   };
 
   useEffect(()=>{
     getData()
   },[])


  const handleAddPromotion = () => {
    navigate('/settings/add-promotion');
  };


  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
      <SettingsMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Promotion</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6}>
              <DemoInfo />
            </Col> */}
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                  <button className={`btn ${tb.upload}`} onClick={handleAddPromotion}>
                      <AiOutlinePlusCircle className={`${tb.icon} m-0`} />
                      Add Promotion
                    </button>
                    <button className={`btn ${tb.upload}`}>
                    
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" />
                    </button>
                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-1`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid promotion={promotion} getData={getData}/>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>

      <LocationM show={show} handleClose={handleClose} />
    </>
  );
}
