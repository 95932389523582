import React from "react";
import cx from "./Sidebar.module.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { MdCategory, MdKeyboardArrowDown, MdListAlt } from "react-icons/md";

// --------- Images --------- //
import student from "../../images/icon-student.svg";
import team from "../../images/icon-team.svg";

const CustomersMenu = () => {
  return (
    <>
      <div className={`${cx.moreMenuList}`}>
        <h5 className={`${cx.moreMenuTitle}`}>
          <div className={`${cx.menuIcon}`}>
            <img src={team} alt="customer-logo" />
          </div>
          <div className={`${cx.menuName}`}>B2C Customers</div>
        </h5>
        <ul>
          <li>
            <NavLink to="/customer-setup/customers">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>B2C Customers List</div>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CustomersMenu;
