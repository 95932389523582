import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getpackages } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const deletePackages = async () => {
    const res = await httpRequest(`delete_Packages/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
    } else {
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.status} api="package_status" id={params?.row?.id}
              apiMethod="json" getLatest={getpackages} />
          </li>
        </ul>
      </div>
      <LocationM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deletePackages={deletePackages} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let row = props?.packageData?.map((val: any, index: any) => {
    let duration: any = 0;
    if (val?.typeOfService?.type === "duration") {
      duration = val?.Services?.reduce((accumulator: number, item1: any) => {
        const itemDuration = parseInt(item1?.noOfServices);
        if (!isNaN(itemDuration)) {
          return accumulator + itemDuration;
        }
        return accumulator;
      }, duration)
    }
    else if (val?.typeOfService?.type === "bundle") {
      duration = ""
    } else {
      duration = val?.Services?.reduce((accumulator: number, item1: any) => {
        const itemDuration = parseInt(item1?.noOfServices);
        if (!isNaN(itemDuration)) {
          return accumulator + itemDuration;
        }
        return accumulator;
      }, duration)
    }
    return {
      Sr_No: index + 1,
      id: val?._id,
      PackageName: val?.PackageName,
      Services: val?.Services?.map((item: any) => { return item?.priceId?.serviceId?.serviceName }),
      typeOfService: val?.typeOfService?.type,
      ServiceAvailableFor: val?.ServiceAvailableFor,
      finalPrice: val?.finalPrice,
      totalPrice: val?.totalPrice,
      BranchName: val?.BranchLocation?.branchName,
      duration: duration,
      status: val?.status,
      businessName: val?.Business?.businessName,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => <StatusButton params={params} getpackages={props.getpackages} />,
    },
    {
      field: "PackageName",
      headerName: "Package Name",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "typeOfService",
      headerName: "Type Of Service",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "ServiceAvailableFor",
      headerName: "Service Available For",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    { field: "businessName", headerName: "Business Name", flex: 1, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "BranchName", headerName: "Branch Name", flex: 1, minWidth: 160, renderCell: renderCellWithTooltip },
    {
      field: "duration",
      headerName: "Duration",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "finalPrice",
      headerName: "Final Price",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },

  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        rowHeight={48}
        pageSize={100}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
