import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { Controller, useForm } from "react-hook-form";
import { Tab, Nav, Row, Col, Accordion } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";
import uploadFile from "../../../images/iconUpload.svg";
import uploadIcon from "../../../images/uploadIcon.svg";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";
import OnOffSwitch from "../../../components/Forms/OnOffSwitch";
import { IoIosCloseCircle } from "react-icons/io";
import crossIcon from '../../../assest/crossRed.svg'
import { httpRequest } from "../../../Apis/commonApis";
import { useLocation, useNavigate } from "react-router-dom";

const AddHeadline = (props: any) => {
    const [headlineData, setHeadlineData] = useState<any>(null);

    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("")
    const handleClose = () => setShow(false);

    const navigate = useNavigate();
    const location = useLocation();

    const [apiError, setApiError] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        control
    } = useForm();

    async function submitHandler(data: any) {
        setLoading(true)
        const path = headlineData ? `updateHeadline/${headlineData?._id}` : "createHeadline";
        const method = headlineData ? 'PATCH' : 'POST'
        const response = await httpRequest(path, method, data, 'json');
        if (response?.status) {
            setShow(true);
            setMessage(response?.message);
            navigate('/settings/headline')
        } else {
            setApiError(response?.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (location?.state?._id)
            getHeadline()
    }, [location?.state]);

    useEffect(() => {
        if (headlineData) {
            setValue('title', headlineData?.title);
            setValue('content', headlineData?.content);
            setValue('url', headlineData?.url);
        }
    }, [headlineData])

    const getHeadline = async () => {
        let res = await httpRequest(`getHeadline?type=list&_id=${location?.state?._id}`, "get", null, "auth");
        if (res?.status) {
            setHeadlineData(res.data?.[0])
        }
    }

    return (
        <>
            <SuccessPasswordModal handleClose={handleClose} show={show} message={message} />

            <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
                <div className={`${pm.pageTitle}`}>
                    <div className={`${pm.titleInfo}`}>
                        {headlineData ? headlineData?.title : "Add Headline"}
                    </div>
                    <div className={`${pm.rightInfo}`}></div>
                </div>

                <div className={`${pm.tabbingBox}`}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="tabNew1">
                                    <img src={basic} alt="info" className={`${pm.default}`} />
                                    Details
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="tabNew1">
                                <Row>
                                    <Col lg={3}>
                                        <div className={`${pm.formBox}`}>
                                            <label className="form-label">Title</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Title"
                                                {...register("title", {
                                                    required: true,
                                                })}
                                            />
                                            {errors?.title?.type === "required" && (
                                                <p className={"errorMessage"}>This field is required</p>
                                            )}
                                            {apiError !== "" && (
                                                <p className={"errorMessage"}>{apiError}</p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className={`${pm.formBox}`}>
                                            <label className="form-label">URL</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="URL"
                                                {...register("url", {
                                                    required: true,
                                                })}
                                            />
                                            {errors?.url?.type === "required" && (
                                                <p className={"errorMessage"}>This field is required</p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={6}></Col>
                                    <Col lg={6}>
                                        <div className={`${pm.formBox}`}>
                                            <label className="form-label">Content</label>
                                            <textarea
                                                rows={6}
                                                style={{minHeight:'100px'}}
                                                className="form-control"
                                                placeholder="Content"
                                                {...register("content", {
                                                    required: true,
                                                })}
                                            />
                                            {errors?.content?.type === "required" && (
                                                <p className={"errorMessage"}>This field is required</p>
                                            )}
                                        </div>
                                    </Col>


                                    <Col md={12}>
                                        <div className={`${pm.formBox}`}>
                                            <div className={`${pm.btnsGroup}`}>
                                                <button
                                                    className={`btn ${pm.btn1}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(-1);
                                                    }}
                                                >
                                                    Back
                                                </button>
                                                <button className={`btn ${pm.btn4}`} disabled={loading} onClick={handleSubmit(submitHandler)}>
                                                    {loading ? (
                                                        <div
                                                            className="spinner-border spinner-border-sm text-light"
                                                            role="status"
                                                        ></div>
                                                    ) : (
                                                        headlineData ? "Update" : 'Save'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
        </>
    );
};

export default AddHeadline;
