import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import cx from "./ServiceCategory.module.scss";
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import BusinessServiceCategoryM from "../../../components/Modals/BusinessServiceCategory";
import { httpRequest } from "../../../Apis/commonApis";
import Tooltip from '@mui/material/Tooltip';
const StatusButton = (props: any) => {
  let { params, getServiceCatagory } = props;
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteServiceCategory = async () => {
    const res = await httpRequest(`delete_Service_Catagory/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload()
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
            <Status status={params.row.status} api="service_category_status" id={params?.id}
              apiMethod="json" getLatest={getServiceCatagory} />
          </li> */}
        </ul>
      </div>

      <BusinessServiceCategoryM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteServiceCategory={deleteServiceCategory} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const ColorIcon = (props: any) => {
  let { params } = props;
  const color = params.row.colorCode;
  const path = params.row.path;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink to={"#"}>
              <ul className={` ${cx.colorBox}`}>
                <li role="img" style={{ background: `${color}` }}></li>
              </ul>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {

  let row = props?.serviceCategory?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      ServiceCategoryIdNo: val?.serviceCategoryIdNo,
      status: val?.status,
      serviceCategoryTag: val?.serviceCategoryTag?.map((item: any) => {
        return item
      }),
      businessName: val?.Business?.businessName,
      colorCode: val?.serviceCategoryColor,
      serviceCategory: val?.serviceCategoryName,
      businessCategory: val?.businessCatagoryId?.BusinessCategoryName,
      icon: val?.businessCatagoryId?.Icon,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 280,
    //   renderCell: (params: any) => <StatusButton params={params} getServiceCatagory={props?.getServiceCatagory} />,
    // },
    {
      field: "ServiceCategoryIdNo",
      headerName: "ServiceCategoryIdNo",
      flex: 1,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "serviceCategory",
      headerName: "Service Category",
      flex: 1,
      minWidth: 120, renderCell: renderCellWithTooltip
    }, { field: "businessName", headerName: "Business Name", flex: 1, minWidth: 160, renderCell: renderCellWithTooltip },
    {
      field: "serviceCategoryTag",
      headerName: "ServiceCategoryTag",
      flex: 1,
      minWidth: 150, renderCell: renderCellWithTooltip
    },

  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        //onRowClick={handleRowClick}
        autoHeight
        // hideFooterPagination={true}
        rowHeight={48}
        pageSize={100}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
