import React from "react";
import { Modal, Button } from "react-bootstrap";

const ViewCategoryModal = ({ show, handleClose, categories }: any) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {categories && categories.length > 0 ? (
          <ul>
            {categories.map((category: any, index: number) => (
              <div key={index}>{category}</div>
            ))}
          </ul>
        ) : (
          <p>There is no category</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewCategoryModal;
