import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { MdClose } from 'react-icons/md';


export const ActiveDeactive = (props: any) => {

    let { show, handleActiveDeactiveClose , handleActiveWithPopup , status, item = ''} = props;
    const message = status
? `Are you sure you want to activate this item?`
: `Are you sure you want to deactivate this item?`;

    return (
        <>
            <Modal
                size="sm"
                centered
                show={show}
                onHide={handleActiveDeactiveClose}
                className={`${m.modalCts}`}
            >
                <Modal.Body>
                    <button
                        className={`${m.closeIcon}`}
                        title="Close"
                        onClick={handleActiveDeactiveClose}
                    >
                        <MdClose />
                    </button>
                    <Col lg={12} className={`${m.title} mb-2`}>
                        <h5>{item ? `${item}` : 'Status'}</h5>
                    </Col>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center py-3 pb-4'>
                                {/* <p style={{fontSize:'22px'}}>Are you confirm active / deactive ?</p> */}
                                {message}
                                {/* <p style={{fontSize:'22px'}}>Are you confirm {handleActiveWithPopup ? "acive" : "deactive"} ?</p> */}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='d-flex gap-3'>
                                <Button
                                    className={`${st.btnDisabled}`} style={{minWidth:'auto', backgroundColor:'#7f8699'}}
                                    onClick={handleActiveDeactiveClose}
                                >
                                    No
                                </Button>
                                <Button
                                    className={`${st.btnDisabled}`} style={{minWidth:'auto',}}
                                    onClick={() => {
                                        handleActiveWithPopup()
                                    }}
                                >
                                    Yes
                                </Button>
                            </div>
                        </Col>
                    </Row>


                </Modal.Body>
            </Modal>
        </>
    )
}

