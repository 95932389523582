import React, { useState } from "react";
import st from "../../style.module.scss";
import { httpRequest } from "../../Apis/commonApis";


const Verify = (props: any) => {
    let { params, api, mobileVerify, emailVerify } = props
    //     const updateStatus = async (e: any,type: string) => {
    //     let statusVal = e.target.checked
    //     if (props.api) {
    //       let body: any;
    //       if (props.apiMethod === 'formdata') {
    //         body = new FormData();
    //         body.append("isActive", statusVal);
    //       } else if(mobileVerify){
    //         body = {
    //             mobileVerify: "true",
    //         };
    //       }else if(emailVerify){
    //         body = {
    //             emailVerify: "true",
    //         };
    //       }
    //       const res = await httpRequest(`${api}/${params.id}`,"PATCH",body,"json");
    //       console.log(res,"statusVal111");
    //       if (res?.status) {
    //         props.getLatest();
    //       }
    //     }
    //   };
    const updateStatus = async (e: any, type: string) => {
        let statusVal = e.target.checked;
        if (props.api) {
            let body: any;
            if (props.apiMethod === 'formdata') {
                body = new FormData();
                body.append("isActive", statusVal);
            } else if (type === 'mobile') {
                body = {
                    mobileVerify: statusVal,
                };
            } else if (type === 'email') {
                body = {
                    emailVerify: statusVal,
                };
            }
            const res = await httpRequest(`${api}/${params.id}`, "PATCH", body, "json");
            if (res?.status) {
                props.getLatest();
            }
        }
    };
    return (
        <>
            <label className={`${st.switch}`}>
                {mobileVerify &&
                    <input
                        className={`${st.switchInput}`}
                        type="checkbox"
                        onClick={(e) => updateStatus(e, 'mobile')}
                        checked={params.mobileVerify}
                        disabled={params.mobileVerify}
                    />
                }
                {emailVerify &&
                    <input
                        className={`${st.switchInput}`}
                        type="checkbox"
                        onClick={(e) => updateStatus(e, 'email')}
                        checked={params.emailVerify}
                        disabled={params.emailVerify}
                    />
                }
                <span className={`${st.switchLabel}`} data-on="Verified" data-off="Unverified"></span>
                <span className={`${st.switchHandle}`}></span>
            </label>

        </>
    );
};


export default Verify;
