import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Pagination } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";
import Tooltip from '@mui/material/Tooltip';
import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import BusinessUserM from "../../../components/Modals/BusinessUserPopup";
import { httpRequest } from "../../../Apis/commonApis";
import ResetPasswordModal from "../../../components/Modals/ResetPasswordModal";

const StatusButton = (props: any) => {
  let { params, getbusinessUser } = props;
  const [show, setShow] = useState(false);
  const [error, seterror] = useState("")
  const [showDelete, setDeleteShow] = useState(false);
  const [businessUser, setbusinessUser] = useState<any>([])
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const deletebusinessUser = async () => {
    const res = await httpRequest(`delete_business_user/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      seterror(res?.message)
      window.location.reload();
    } else {
      seterror(res?.message)
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params.status} api="updateStatus" id={params?.id} getLatest={getbusinessUser}
              apiMethod="json" item={`${params?.fName || ''} ${params?.lName || ''}`} />
          </li>
        </ul>
      </div>


      <ResetPasswordModal show={show} api="businessUserChangepassword" userData={params?.id} handleClose={handleClose} />
      {/* <BusinessUserM show={show}  userData={params} handleClose={handleClose} /> */}
      <DeletePopup show={showDelete} deletebusinessUser={deletebusinessUser} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params.value}>
    <div>{params.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let data: any = props?.businessUserData;
  const rowsPerPage = 10;
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = data?.slice(startIndex, endIndex);
  let rows = data?.map((val: any, index: any) => {
    const srNo = startIndex + index + 1;
    return {
      Sr_No: srNo,
      id: val?._id,
      // businessName: val?.businessName,
      fName: val?.firstName,
      lName: val?.lastName,
      email: val?.email,
      status: val?.status,
      phoneNumber: val?.mobile,
      customerAccountNo: val?.customerAccountNo,
      stepCompleted: val?.stepCompleted,
      country: val?.country,
      emailVerified: val?.emailVerified,
      mobileVerified: val?.mobileVerified,
      deviceType: val?.deviceType,
      userIp: val?.userIp,
      action: val?.isActive,
    };
  });
  const handlePageChange = (event: any, page: any) => {
    setCurrentPage(page);
  };
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 230,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => <StatusButton params={params?.row} getbusinessUser={props?.getbusinessUser} />,
    },
    { field: "fName", headerName: "First Name", flex: 1, minWidth: 90, renderCell: renderCellWithTooltip },
    { field: "lName", headerName: "Last Name", flex: 1, minWidth: 90, renderCell: renderCellWithTooltip },
    { field: "email", headerName: "Email", flex: 1, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "customerAccountNo", headerName: "Customer Account No", flex: 1, minWidth: 180, renderCell: renderCellWithTooltip },
    { field: "country", headerName: "Country", flex: 1, minWidth: 140, },
    { field: "emailVerified", headerName: "Email Verified", flex: 1, minWidth: 140 },
    { field: "mobileVerified", headerName: "Mobile Verified", flex: 1, minWidth: 140 },
    { field: "deviceType", headerName: "DeviceType", flex: 1, minWidth: 140 },
    { field: "userIp", headerName: "User Ip", flex: 1, minWidth: 140, renderCell: renderCellWithTooltip },
    { field: "stepCompleted", headerName: "Status", flex: 1, minWidth: 140 },

  ];
  return (
    <div>
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        // hideFooterPagination={false} 
        rowHeight={48}
        headerHeight={48}
        pageSize={100}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        getRowId={(row) => row?.id}
      />

    </div>
  );
}
